import { Typography, Box, Tooltip, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface SectionHeaderProps {
  title: string;
  tooltip?: string;
}

export const SectionHeader = ({ title, tooltip }: SectionHeaderProps) => (
  <Box display="flex" alignItems="center" mb={2}>
    <Typography variant="h6" component="h2">
      {title}
    </Typography>
    {tooltip && (
      <Tooltip title={tooltip}>
        <IconButton size="small" sx={{ ml: 1 }}>
          <HelpOutlineIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    )}
  </Box>
);
