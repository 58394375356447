import { Typography } from '@mui/material';
import FormTextField from './FormTextField';

interface ReportInformationSectionProps {
  description: string;
  reportPurpose: string;
  scope: string;
  exclusiveUse: string;
  reportLimitations: string;
  photographs: string;
  thirdPartyInterpretation: string;
  generalConditions: string;
  warrantyDisclaimer: string;
  buildingCodeCompliance: string;
  onFieldChange: (field: string, value: string) => void;
}

const ReportInformationSection = ({
  description,
  reportPurpose,
  scope,
  exclusiveUse,
  reportLimitations,
  photographs,
  thirdPartyInterpretation,
  generalConditions,
  warrantyDisclaimer,
  buildingCodeCompliance,
  onFieldChange,
}: ReportInformationSectionProps) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Report Purpose & Scope
      </Typography>

      <FormTextField
        rows={4}
        label="Description"
        placeholder="Enter the description of building envelope inspection"
        value={description}
        onChange={(value) => onFieldChange('description', value)}
      />

      <FormTextField
        rows={4}
        label="Report Purpose"
        placeholder="Enter the purpose of the report"
        value={reportPurpose}
        onChange={(value) => onFieldChange('reportPurpose', value)}
      />

      <FormTextField
        rows={6}
        label="Scope"
        placeholder="Enter the scope of the inspection"
        value={scope}
        onChange={(value) => onFieldChange('scope', value)}
      />

      <FormTextField
        rows={4}
        label="Exclusive Use"
        placeholder="Enter exclusive use terms"
        value={exclusiveUse}
        onChange={(value) => onFieldChange('exclusiveUse', value)}
      />

      <FormTextField
        rows={4}
        label="Report Limitations"
        placeholder="Enter report limitations"
        value={reportLimitations}
        onChange={(value) => onFieldChange('reportLimitations', value)}
      />

      <FormTextField
        rows={4}
        label="Photographs"
        placeholder="Enter photograph usage terms"
        value={photographs}
        onChange={(value) => onFieldChange('photographs', value)}
      />

      <FormTextField
        rows={4}
        label="Third Party Interpretation"
        placeholder="Enter third party interpretation terms"
        value={thirdPartyInterpretation}
        onChange={(value) => onFieldChange('thirdPartyInterpretation', value)}
      />

      <FormTextField
        rows={6}
        label="General Conditions"
        placeholder="Enter general conditions"
        value={generalConditions}
        onChange={(value) => onFieldChange('generalConditions', value)}
      />

      <FormTextField
        rows={4}
        label="Warranty Disclaimer"
        placeholder="Enter warranty disclaimer"
        value={warrantyDisclaimer}
        onChange={(value) => onFieldChange('warrantyDisclaimer', value)}
      />

      <FormTextField
        rows={4}
        label="Building Code Compliance"
        placeholder="Enter building code compliance terms"
        value={buildingCodeCompliance}
        onChange={(value) => onFieldChange('buildingCodeCompliance', value)}
      />
    </>
  );
};

export default ReportInformationSection;
