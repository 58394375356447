import { FC } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingPreview: FC = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '400px',
      gap: 2
    }}
  >
    <CircularProgress size={40} />
    <Typography variant="body1" color="text.secondary">
      Generating preview...
    </Typography>
  </Box>
);

export default LoadingPreview;
