import React from 'react';
import { Page, Text, Document, StyleSheet, Image, DocumentProps } from '@react-pdf/renderer';
import { Invoice } from '../../../components/invoices/types';
import { formatCurrency } from '../../../utils/numberFormat';

// Props that this component accepts
interface Props extends DocumentProps {
  invoice: Invoice;
  invoiceBase?: string;  // The background image for the invoice
}

const InvoiceDocument = ({ invoice, invoiceBase, ...props }: Props) => {
  return (
    <Document {...props}>
      {/* The A4 page that contains all invoice elements */}
      <Page size="A4" style={{ position: 'relative', width: '100%', height: '100%' }}>
        {/* Background Image - Only shown if invoiceBase is provided */}
        {invoiceBase && (
          <Image 
            src={invoiceBase} 
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }}
            fixed
          />
        )}

        {/* Invoice Number  */}
        <Text style={{
          position: 'absolute',
          top: 365,
          left: 470,
          color: 'black',
          fontSize: 12,
          fontFamily: 'Helvetica',
          width: 100,
          textAlign: 'left'
        }}>
          {invoice.invoiceNumber}
        </Text>

        {/* Invoice Date - */}
        <Text style={{
          position: 'absolute',
          top: 365,
          left: 90,
          color: 'black',
          fontSize: 12,
          fontFamily: 'Helvetica',
          width: 100
        }}>
          {new Date(invoice.date).toLocaleDateString()}
        </Text>

        {/* Due Date -  */}
        <Text style={{
          position: 'absolute',
          top: 365,
          left: 330,
          color: 'red',
          fontSize: 12,
          fontFamily: 'Helvetica',
          width: 100
        }}>
          ({new Date(invoice.dueDate).toLocaleDateString()})
        </Text>

        {/* Client Name */}
        <Text style={{
          position: 'absolute',
          top: 240,
          left: 90,
          color: 'black',
          fontSize: 12,
          fontFamily: 'Helvetica',
          width: 200
        }}>
          {invoice.clientName}
        </Text>

        {/* Client Address Line 2 (C/O) */}
        <Text style={{
          position: 'absolute',
          top: 255,
          left: 90,
          color: 'black',
          fontSize: 12,
          fontFamily: 'Helvetica',
          width: 200
        }}>
          {invoice.clientAddressLine2}
        </Text>

        {/* Client Street Address */}
        <Text style={{
          position: 'absolute',
          top: 270,
          left: 90,
          color: 'black',
          fontSize: 12,
          fontFamily: 'Helvetica',
          width: 200
        }}>
          {invoice.clientStreetAddress}
        </Text>

        {/* Client City, State ZIP */}
        <Text style={{
          position: 'absolute',
          top: 285,
          left: 90,
          color: 'black',
          fontSize: 12,
          fontFamily: 'Helvetica',
          width: 200
        }}>
          {`${invoice.clientCity}, ${invoice.clientState} ${invoice.clientZip}`}
        </Text>

        {/* First Item Description */}
        <Text style={{
          position: 'absolute',
          top: 420,  // Move up/down by changing this value
          left: 60,  // Move left/right by changing this value
          color: 'black',
          fontSize: 12,
          fontFamily: 'Helvetica',
          width: 410
        }}>
          {invoice.items[0]?.description}
        </Text>

        {/* First Item Amount */}
        <Text style={{
          position: 'absolute',
          top: 420,  // Should match its description's top value
          right: 60,  // Move left/right by changing this value
          color: 'black',
          fontSize: 12,
          fontFamily: 'Helvetica',
          width: 90,
          textAlign: 'right'
        }}>
          ${formatCurrency(invoice.items[0]?.amount)}
        </Text>

        {/* Total Amount - Bottom Right */}
        <Text style={{
          position: 'absolute',
          bottom: 245,  // Move up/down by changing this value
          right: 60,    // Move left/right by changing this value
          color: 'black',
          fontSize: 14,
          fontFamily: 'Helvetica',
          width: 100,
          textAlign: 'right'
        }}>
          ${formatCurrency(invoice.amount)}
        </Text>
      </Page>
    </Document>
  );
};

export default InvoiceDocument;
