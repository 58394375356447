import { FC } from 'react';
import { Typography, Button, Box } from '@mui/material';

export const ReportsHeader: FC = () => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
    <Typography variant="h5">
      Inspection Reports
    </Typography>
    <Button
      variant="contained"
      color="primary"
      href="/new-inspection"
    >
      Create New Report
    </Button>
  </Box>
);
