import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box
} from '@mui/material';
import LoadingPreview from './preview/LoadingPreview';
import { ErrorDisplay } from './preview/ErrorDisplay';
import { PreviewHeader } from './preview/PreviewHeader';
import { usePreviewState } from './preview/usePreviewState';
import { ReportPreviewProps } from './preview/types';

const ReportPreview = ({
  report,
  open,
  onClose,
  onGeneratePDF,
  formatDate
}: ReportPreviewProps) => {
  const { preview, loading, error, handleRetry } = usePreviewState({ report, open });

  if (!report) return null;

  const handleGeneratePDF = () => {
    console.log('Generating PDF with report data:', {
      id: report.id,
      photoCount: report.photos?.length || 0,
      photos: report.photos,
      buildingSections: Object.keys(report.buildingSections || {})
    });
    
    onGeneratePDF(report);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        sx: { 
          minHeight: '90vh',
          maxHeight: '90vh'
        }
      }}
    >
      <DialogTitle>
        <PreviewHeader 
          onGeneratePDF={handleGeneratePDF}
          loading={loading}
        />
      </DialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        {loading ? (
          <LoadingPreview />
        ) : error ? (
          <ErrorDisplay 
            error={error}
            onRetry={handleRetry}
            report={report}
          />
        ) : (
          <Box height="calc(100% - 16px)">
            {preview}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReportPreview;
