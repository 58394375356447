import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { formatCurrency } from '../../../utils/numberFormat';
import { InspectionReport, Settings } from '../../types';

const styles = StyleSheet.create({
  container: {
    padding: 30,
    fontFamily: 'Times-Roman',
  },
  header: {
    backgroundColor: '#1976D2',
    padding: 15,
    marginBottom: 20,
    borderRadius: 4,
  },
  title: {
    fontSize: 24,
    color: '#FFFFFF',
    fontFamily: 'Times-Bold',
  },
  subTitle: {
    fontSize: 16,
    marginTop: 25,
    marginBottom: 15,
    color: '#1976D2',
    fontFamily: 'Times-Bold',
    borderBottom: '2pt solid #1976D2',
    paddingBottom: 5,
  },
  costSummaryContainer: {
    marginTop: 15,
    backgroundColor: '#F5F5F5',
    padding: 15,
    borderRadius: 4,
  },
  costRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    paddingBottom: 8,
    borderBottom: '0.5pt solid #E0E0E0',
  },
  totalCostRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    borderTopWidth: 2,
    borderTopColor: '#1976D2',
    paddingTop: 10,
    backgroundColor: '#E3F2FD',
    padding: 10,
    borderRadius: 4,
  },
  costLabel: {
    fontSize: 12,
    color: '#424242',
  },
  costLabelCritical: {
    fontSize: 12,
    color: '#D32F2F',
    fontFamily: 'Times-Bold',
  },
  costLabelModerate: {
    fontSize: 12,
    color: '#F57C00',
    fontFamily: 'Times-Bold',
  },
  costLabelInfo: {
    fontSize: 12,
    color: '#0288D1',
    fontFamily: 'Times-Bold',
  },
  costValue: {
    fontSize: 12,
    fontFamily: 'Times-Bold',
    color: '#424242',
  },
  costValueCritical: {
    fontSize: 12,
    fontFamily: 'Times-Bold',
    color: '#D32F2F',
  },
  costValueModerate: {
    fontSize: 12,
    fontFamily: 'Times-Bold',
    color: '#F57C00',
  },
  costValueInfo: {
    fontSize: 12,
    fontFamily: 'Times-Bold',
    color: '#0288D1',
  },
  totalLabel: {
    fontSize: 14,
    fontFamily: 'Times-Bold',
    color: '#1976D2',
  },
  totalValue: {
    fontSize: 14,
    fontFamily: 'Times-Bold',
    color: '#1976D2',
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
    color: '#424242',
  },
});

interface Props {
  report: InspectionReport;
  settings: Settings;
}

const ExecutiveSummary: React.FC<Props> = ({ report, settings }) => {
  // Group photos by severity level
  const groupPhotosBySeverity = () => {
    const photos = report.photos || [];
    return {
      criticalIssues: photos.filter(photo => photo.severityLevel?.toLowerCase() === 'critical'),
      moderateIssues: photos.filter(photo => photo.severityLevel?.toLowerCase() === 'moderate'),
      informationalIssues: photos.filter(photo => 
        photo.severityLevel?.toLowerCase() === 'informational' || 
        photo.severityLevel?.toLowerCase() === 'low'
      ),
    };
  };

  // Calculate costs by severity
  const calculateCost = (photos: any[]) => {
    return photos.reduce((sum, photo) => {
      const cost = parseFloat(photo.estimatedCost || '0');
      return isNaN(cost) ? sum : sum + cost;
    }, 0);
  };

  const { criticalIssues, moderateIssues, informationalIssues } = groupPhotosBySeverity();
  const criticalCost = calculateCost(criticalIssues);
  const moderateCost = calculateCost(moderateIssues);
  const informationalCost = calculateCost(informationalIssues);
  const totalEstimatedCost = criticalCost + moderateCost + informationalCost;

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>Executive Summary</Text>
      </View>
      
      <Text style={styles.subTitle}>Cost Summary</Text>
      
      <View style={styles.costSummaryContainer}>
        <View style={styles.costRow}>
          <Text style={styles.costLabelCritical}>Critical Issues Cost:</Text>
          <Text style={styles.costValueCritical}>${formatCurrency(criticalCost)}</Text>
        </View>
        
        <View style={styles.costRow}>
          <Text style={styles.costLabelModerate}>Moderate Issues Cost:</Text>
          <Text style={styles.costValueModerate}>${formatCurrency(moderateCost)}</Text>
        </View>
        
        <View style={styles.costRow}>
          <Text style={styles.costLabelInfo}>Informational Issues Cost:</Text>
          <Text style={styles.costValueInfo}>${formatCurrency(informationalCost)}</Text>
        </View>
        
        <View style={styles.totalCostRow}>
          <Text style={styles.totalLabel}>Total Estimated Cost:</Text>
          <Text style={styles.totalValue}>${formatCurrency(totalEstimatedCost)}</Text>
        </View>
      </View>
    </View>
  );
};

export default ExecutiveSummary;
