import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Template } from '../../../types';
import { styles } from '../../styles/pdfStyles';

interface GeneralSectionProps {
  template: Template;
}

export const GeneralSection: React.FC<GeneralSectionProps> = ({ template }) => {
  const sections = [
    { 
      title: 'General Conditions',
      content: template.generalConditionsSection,
      icon: '🏢'
    },
    { 
      title: 'Importance of Annual Inspection',
      content: template.importance,
      icon: '📅'
    },
    { 
      title: 'Resolution',
      content: template.resolution,
      icon: '✓'
    },
    { 
      title: 'Warranty Disclaimer',
      content: template.warrantyDisclaimer,
      icon: '⚠️'
    }
  ];

  return (
    <View style={styles.section}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>General Information</Text>
      </View>

      {/* Content Container */}
      <View style={[styles.contentBlock, { marginTop: 15 }]}>
        {sections.map((section, index) => (
          <View 
            key={section.title}
            style={[
              {
                marginBottom: index === sections.length - 1 ? 0 : 20,
                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F5F5F5',
                padding: 15,
                borderRadius: 4,
                borderLeft: '4pt solid #1976D2',
              }
            ]}
          >
            {/* Section Header */}
            <View style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 10,
            }}>
              {/* Section Title */}
              <Text style={{
                fontSize: 16,
                fontFamily: 'Times-Bold',
                color: '#1976D2',
              }}>
                {section.title}
              </Text>
            </View>

            {/* Section Content */}
            <Text style={{
              fontSize: 12,
              color: '#424242',
              lineHeight: 1.5,
            }}>
              {section.content || 'Not specified'}
            </Text>
          </View>
        ))}
      </View>

      {/* Optional Note */}
      {!sections.some(section => section.content) && (
        <Text style={[styles.text, { 
          fontStyle: 'italic',
          textAlign: 'center',
          marginTop: 10,
          color: '#616161'
        }]}>
          No general information has been specified.
        </Text>
      )}
    </View>
  );
};
