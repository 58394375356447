import { useEffect, useCallback } from 'react';
import { useTemplateState } from './useTemplateState';
import { useTemplateOperations } from './useTemplateOperations';

export const useIntroTemplate = () => {
  const {
    template,
    setTemplate,
    handleChange
  } = useTemplateState();

  const {
    isLoading,
    isSaving,
    error,
    success,
    fetchTemplate,
    saveTemplate,
    clearSuccess,
    clearError
  } = useTemplateOperations(template, setTemplate);

  useEffect(() => {
    void fetchTemplate();
  }, [fetchTemplate]);

  const handleSave = useCallback(async () => {
    await saveTemplate();
  }, [saveTemplate]);

  return {
    // Template state
    template,
    handleChange,

    // Loading states
    isLoading,
    isSaving,

    // Feedback states
    error,
    success,
    clearError,
    clearSuccess,

    // Operations
    handleSave
  };
};
