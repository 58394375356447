import { useState, useCallback } from 'react';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db, auth } from '../../../firebase/config';
import { IntroTemplateData } from '../types';

interface TemplateOperationsState {
  isLoading: boolean;
  isSaving: boolean;
  error: string | null;
  success: boolean;
}

export const useTemplateOperations = (
  template: IntroTemplateData,
  setTemplate: (template: IntroTemplateData) => void
) => {
  const [state, setState] = useState<TemplateOperationsState>({
    isLoading: true,
    isSaving: false,
    error: null,
    success: false
  });

  const updateState = useCallback((updates: Partial<TemplateOperationsState>) => {
    setState(prev => ({ ...prev, ...updates }));
  }, []);

  const handleError = useCallback((error: unknown) => {
    const errorMessage = error instanceof Error ? error.message : 'An error occurred';
    updateState({ error: errorMessage, success: false });
    console.error('Template operation error:', error);
  }, [updateState]);

  const fetchTemplate = useCallback(async () => {
    try {
      updateState({ isLoading: true, error: null });
      
      // Check if user is authenticated
      if (!auth.currentUser) {
        throw new Error('Authentication required to access templates');
      }
      
      const templateRef = doc(db, 'templates', 'introTemplate');
      const templateSnap = await getDoc(templateRef);
      
      if (templateSnap.exists()) {
        const data = templateSnap.data() as IntroTemplateData;
        setTemplate(data);
      }
      // If template doesn't exist, we'll use the default template
      // and it will be created on first save
    } catch (error) {
      handleError(error);
    } finally {
      updateState({ isLoading: false });
    }
  }, [setTemplate, updateState, handleError]);

  const saveTemplate = useCallback(async () => {
    try {
      updateState({ isSaving: true, error: null });
      
      // Check if user is authenticated
      if (!auth.currentUser) {
        throw new Error('Authentication required to save templates');
      }
      
      const templateRef = doc(db, 'templates', 'introTemplate');
      await setDoc(templateRef, {
        ...template,
        updatedAt: new Date().toISOString(),
        createdAt: template.createdAt || new Date().toISOString()
      });
      
      updateState({ success: true });
    } catch (error) {
      handleError(error);
    } finally {
      updateState({ isSaving: false });
    }
  }, [template, updateState, handleError]);

  const clearSuccess = useCallback(() => updateState({ success: false }), [updateState]);
  const clearError = useCallback(() => updateState({ error: null }), [updateState]);

  return {
    ...state,
    fetchTemplate,
    saveTemplate,
    clearSuccess,
    clearError
  };
};
