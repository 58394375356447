import { FormData } from '../types';

export const validatePropertyDetails = (formData: FormData): boolean => {
  return Boolean(
    formData.clientName &&
    formData.propertyAddress &&
    formData.inspectionDate &&
    formData.propertyType
  );
};

export const getStepValidation = (step: number, formData: FormData): boolean => {
  switch (step) {
    case 0:
      return validatePropertyDetails(formData);
    case 1:
      return true; // Photos are optional
    case 2:
      return true; // General notes are optional
    default:
      return false;
  }
};

export const getStepErrorMessage = (step: number): string => {
  switch (step) {
    case 0:
      return 'Please fill in all property details before proceeding.';
    case 1:
      return '';
    case 2:
      return '';
    default:
      return 'Invalid step';
  }
};
