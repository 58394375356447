import React from 'react';
import { Box, Typography, Button, CircularProgress, useTheme, useMediaQuery } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

interface UploadAreaProps {
  uploading: boolean;
  onPhotoUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onError?: () => void;
}

const UploadArea: React.FC<UploadAreaProps> = ({ 
  uploading, 
  onPhotoUpload,
  onError
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      onPhotoUpload(e);
    } catch (error) {
      onError?.();
    }
  };

  return (
    <Box
      sx={{
        border: '2px dashed #ccc',
        borderRadius: 2,
        p: isMobile ? 2 : 3,
        mb: 3,
        textAlign: 'center',
        backgroundColor: '#fafafa',
        cursor: 'pointer',
        '&:hover': {
          borderColor: 'primary.main',
          backgroundColor: '#f5f5f5'
        }
      }}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleChange}
        disabled={uploading}
        style={{ display: 'none' }}
        id="photo-upload-input"
        multiple={!isMobile}
        capture={isMobile ? "environment" : undefined}
      />
      <label htmlFor="photo-upload-input">
        <Button
          component="span"
          variant="contained"
          startIcon={uploading ? (
            <CircularProgress size={20} />
          ) : isMobile ? (
            <PhotoCameraIcon />
          ) : (
            <UploadIcon />
          )}
          disabled={uploading}
          sx={{ 
            mb: 1,
            py: isMobile ? 1.5 : 1,
            px: isMobile ? 3 : 2,
            fontSize: isMobile ? '1rem' : undefined
          }}
        >
          {uploading ? 'Uploading...' : isMobile ? 'Take Photo' : 'Upload Photos'}
        </Button>
        <Typography 
          variant="body2" 
          color="textSecondary" 
          sx={{ 
            mt: 1,
            fontSize: isMobile ? '0.875rem' : undefined
          }}
        >
          {isMobile ? 
            'Take a photo or choose from gallery' : 
            'Click to upload photos of the issues'
          }
        </Typography>
      </label>
    </Box>
  );
};

export default UploadArea;
