import React from 'react';
import { View, Text, Link } from '@react-pdf/renderer';
import { styles } from '../styles/pdfStyles';

interface TableOfContentsProps {
  sections: Array<{
    title: string;
    pageNumber: number;
  }>;
}

export const TableOfContents: React.FC<TableOfContentsProps> = ({ sections }) => {
  return (
    <View style={styles.section}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>Table of Contents</Text>
      </View>

      {/* Content */}
      <View style={[styles.contentBlock, { marginTop: 15 }]}>
        {sections.map((section, index) => (
          <View 
            key={section.title}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: index === sections.length - 1 ? 0 : 10,
              paddingBottom: index === sections.length - 1 ? 0 : 10,
              borderBottom: index === sections.length - 1 ? 'none' : '1pt dotted #E0E0E0',
            }}
          >
            <Text style={{
              fontSize: 12,
              color: '#1976D2',
              fontFamily: 'Times-Roman',
            }}>
              {section.title}
            </Text>
            <Text style={{
              fontSize: 12,
              color: '#424242',
              fontFamily: 'Times-Roman',
            }}>
              {section.pageNumber}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};
