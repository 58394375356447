import React from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { InspectionReport, Settings, Template } from '../../types';
import { styles } from '../styles/pdfStyles';
import { CoverPage } from './CoverPage';
import { TableOfContents } from './TableOfContents';
import { ReportInformationSection } from './sections/ReportInformationSection';
import { BuildingEnvelopeSection } from './sections/BuildingEnvelopeSection';
import ExecutiveSummary from './ExecutiveSummary';
import { CausesOfDistressSection } from './sections/CausesOfDistressSection';
import { BuildingComponentsSection } from './sections/BuildingComponentsSection';
import { GeneralSection } from './sections/GeneralSection';
import { IssuesListSection } from './sections/IssuesListSection';
import PhotoDocumentationSection from './sections/PhotoDocumentationSection';
import { formatCurrency } from '../../../utils/numberFormat';

interface InspectionReportDocumentProps {
  report: InspectionReport;
  settings: Settings;
  template: Template;
}

export const InspectionReportDocument: React.FC<InspectionReportDocumentProps> = ({
  report,
  settings,
  template
}) => {
  // Helper function to check if a section has content
  const hasSectionContent = (section: any) => {
    return section && Object.keys(section).length > 0;
  };

  // Helper function to check if there are any issues to display
  const hasIssues = () => {
    if (report.issues && report.issues.length > 0) return true;
    if (report.photos && report.photos.length > 0) {
      return report.photos.some(photo => photo.severityLevel);
    }
    return false;
  };

  // Organize building sections by priority
  const organizeBuildingSections = () => {
    if (!report.buildingSections) return [];
    
    return Object.entries(report.buildingSections)
      .filter(([_, section]) => section && hasSectionContent(section))
      .sort((a, b) => {
        const conditionA = Number(a[1].condition) || 5;
        const conditionB = Number(b[1].condition) || 5;
        return conditionA - conditionB;
      });
  };

  // Generate table of contents data
  const generateTableOfContents = () => {
    let currentPage = 2; // Start after cover page
    const sections = [];

    // Add fixed sections
    sections.push({ title: 'Report Information', pageNumber: currentPage++ });
    sections.push({ title: 'Executive Summary', pageNumber: currentPage++ });

    // Add conditional sections
    if (hasSectionContent(template.buildingEnvelope)) {
      sections.push({ title: 'Building Envelope', pageNumber: currentPage++ });
    }

    if (hasSectionContent(template.buildingComponents)) {
      sections.push({ title: 'Building Components', pageNumber: currentPage++ });
    }

    if (hasSectionContent(template.causesOfDistress)) {
      sections.push({ title: 'Causes of Distress', pageNumber: currentPage++ });
    }

    if (hasSectionContent(template.general)) {
      sections.push({ title: 'General', pageNumber: currentPage++ });
    }

    if (hasIssues()) {
      sections.push({ title: 'Issues List', pageNumber: currentPage++ });
    }

    if (report.photos && report.photos.length > 0) {
      sections.push({ title: 'Photo Documentation', pageNumber: currentPage++ });
    }

    // Add building sections
    const buildingSections = organizeBuildingSections();
    buildingSections.forEach(([_, section]) => {
      sections.push({ title: section.name, pageNumber: currentPage++ });
    });

    return sections;
  };

  const PageFooter = () => (
    <View style={styles.footer} fixed>
      <Text style={styles.footerText}>
        {[
          settings.company.companyName,
          settings.company.phone,
          settings.company.email,
          settings.company.website
        ].filter(Boolean).join(' • ')}
      </Text>
      <Text 
        style={styles.pageNumber} 
        render={({ pageNumber, totalPages }) => (`${pageNumber - 1} / ${totalPages - 1}`)} 
      />
    </View>
  );

  const renderPage = (content: React.ReactNode, key?: string) => (
    <Page key={key} size="A4" style={styles.page}>
      <View style={styles.contentPage}>{content}</View>
      <PageFooter />
    </Page>
  );

  const getConditionColor = (condition: number) => {
    if (condition <= 2) return '#D32F2F';
    if (condition <= 3) return '#F57C00';
    return '#0288D1';
  };

  const renderBuildingSection = (section: any, key: string) => (
    <View key={key} style={styles.section}>
      <View style={styles.header}>
        <Text style={styles.title}>{section.name}</Text>
      </View>
      
      <View style={[styles.conditionContainer, { marginTop: 15 }]}>
        <Text style={{
          fontSize: 16,
          fontFamily: 'Times-Bold',
          color: getConditionColor(Number(section.condition) || 5),
        }}>
          Condition Rating: {section.condition}/5
        </Text>
      </View>

      <View style={[styles.contentBlock, { marginTop: 15 }]}>
        {section.findings && (
          <View style={{
            marginBottom: 15,
            padding: 12,
            backgroundColor: '#FFFFFF',
            borderRadius: 4,
            borderLeft: '4pt solid #1976D2',
          }}>
            <Text style={{
              fontSize: 14,
              fontFamily: 'Times-Bold',
              color: '#1976D2',
              marginBottom: 8,
            }}>
              Findings
            </Text>
            <Text style={{
              fontSize: 12,
              color: '#424242',
              lineHeight: 1.4,
            }}>
              {section.findings}
            </Text>
          </View>
        )}

        {section.recommendations && (
          <View style={{
            marginBottom: 15,
            padding: 12,
            backgroundColor: '#FFFFFF',
            borderRadius: 4,
            borderLeft: '4pt solid #1976D2',
          }}>
            <Text style={{
              fontSize: 14,
              fontFamily: 'Times-Bold',
              color: '#1976D2',
              marginBottom: 8,
            }}>
              Recommendations
            </Text>
            <Text style={{
              fontSize: 12,
              color: '#424242',
              lineHeight: 1.4,
            }}>
              {section.recommendations}
            </Text>
          </View>
        )}

        {section.estimatedCost && (
          <View style={styles.totalCostRow}>
            <Text style={styles.totalLabel}>Estimated Cost:</Text>
            <Text style={styles.totalValue}>
              ${formatCurrency(section.estimatedCost)}
            </Text>
          </View>
        )}
      </View>
    </View>
  );

  const tableOfContents = generateTableOfContents();

  return (
    <Document>
      <CoverPage report={report} settings={settings} />
      
      {renderPage(<TableOfContents sections={tableOfContents} />, 'table-of-contents')}
      
      {renderPage(<ReportInformationSection template={template} />, 'report-information')}

      {renderPage(<ExecutiveSummary report={report} settings={settings} />, 'executive-summary')}

      {hasSectionContent(template.buildingEnvelope) && 
        renderPage(<BuildingEnvelopeSection template={template} />, 'building-envelope')}

      {hasSectionContent(template.buildingComponents) && 
        renderPage(<BuildingComponentsSection template={template} />, 'building-components')}

      {hasSectionContent(template.causesOfDistress) && 
        renderPage(<CausesOfDistressSection template={template} />, 'causes-of-distress')}

      {hasSectionContent(template.general) && 
        renderPage(<GeneralSection template={template} />, 'general')}

      {hasIssues() && renderPage(<IssuesListSection report={report} />, 'issues-list')}

      {report.photos && report.photos.length > 0 && renderPage(
        <PhotoDocumentationSection report={report} />,
        'photo-documentation'
      )}

      {organizeBuildingSections().map(([key, section]) => 
        renderPage(renderBuildingSection(section, key), `building-section-${key}`)
      )}
    </Document>
  );
};
