import React from 'react';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import FormTextField from '../common/FormTextField';
import FormSelect from '../common/FormSelect';
import Toast from '../common/Toast';
import type { BasicInformationProps } from './types';
import useToast from '../../hooks/useToast';

const propertyTypes = [
  'Residential',
  'Commercial',
  'Industrial',
  'Mixed-Use',
  'Other'
];

const BasicInformation: React.FC<BasicInformationProps> = ({
  clientName,
  propertyAddress,
  inspectionDate,
  propertyType,
  onBasicInfoChange,
  onPropertyTypeChange
}) => {
  const { toast, showToast, hideToast } = useToast();

  const validateField = (name: string, value: string): string => {
    if (!value.trim()) {
      return `${name} is required`;
    }
    return '';
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    if (error) {
      showToast(error, 'error');
    }
  };

  const handlePropertyTypeChange = (event: SelectChangeEvent<string>) => {
    onPropertyTypeChange({ target: { value: event.target.value } });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormTextField
          fullWidth
          label="Client Name"
          name="clientName"
          value={clientName}
          onChange={onBasicInfoChange}
          onBlur={handleBlur}
          error={!clientName.trim() ? 'Client name is required' : ''}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          fullWidth
          label="Property Address"
          name="propertyAddress"
          value={propertyAddress}
          onChange={onBasicInfoChange}
          onBlur={handleBlur}
          error={!propertyAddress.trim() ? 'Property address is required' : ''}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormTextField
          fullWidth
          label="Inspection Date"
          name="inspectionDate"
          type="date"
          value={inspectionDate}
          onChange={onBasicInfoChange}
          onBlur={handleBlur}
          error={!inspectionDate ? 'Inspection date is required' : ''}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormSelect
          fullWidth
          label="Property Type"
          value={propertyType}
          onChange={handlePropertyTypeChange}
          error={!propertyType ? 'Property type is required' : undefined}
        >
          <MenuItem value="">Select a type</MenuItem>
          {propertyTypes.map(type => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      <Toast 
        open={toast.open} 
        message={toast.message} 
        severity={toast.severity} 
        onClose={hideToast} 
      />
    </Grid>
  );
};

export default BasicInformation;
