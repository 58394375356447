import { BuildingComponents } from '../types';

export interface FieldConfig {
  label: string;
  placeholder: string;
  validation?: (value: string) => boolean;
  errorMessage?: string;
}

export type BuildingComponentFieldConfig = Record<keyof BuildingComponents, FieldConfig>;

export const BUILDING_COMPONENT_FIELDS: Array<keyof BuildingComponents> = [
  'roofing',
  'siding',
  'windowsAndDoors',
  'decking',
  'foundation'
];

export const FIELD_CONFIG: BuildingComponentFieldConfig = {
  roofing: {
    label: 'Roofing Components',
    placeholder: 'Enter roofing components description and inspection requirements',
    validation: (value) => value.length >= 10,
    errorMessage: 'Roofing description must be at least 10 characters long'
  },
  siding: {
    label: 'Siding Components',
    placeholder: 'Enter siding components description and inspection requirements',
    validation: (value) => value.length >= 10,
    errorMessage: 'Siding description must be at least 10 characters long'
  },
  windowsAndDoors: {
    label: 'Windows & Doors',
    placeholder: 'Enter windows and doors inspection requirements',
    validation: (value) => value.length >= 10,
    errorMessage: 'Windows & doors description must be at least 10 characters long'
  },
  decking: {
    label: 'Decking Components',
    placeholder: 'Enter decking components description and inspection requirements',
    validation: (value) => value.length >= 10,
    errorMessage: 'Decking description must be at least 10 characters long'
  },
  foundation: {
    label: 'Foundation Components',
    placeholder: 'Enter foundation components description and inspection requirements',
    validation: (value) => value.length >= 10,
    errorMessage: 'Foundation description must be at least 10 characters long'
  }
};
