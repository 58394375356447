import { FC } from 'react';
import { useReportsList } from './reports/hooks/useReportsList';
import { useReportActions } from './reports/hooks/useReportActions';
import { ReportsLayout } from './reports/layout/ReportsLayout';
import { ReportsHeader } from './reports/header/ReportsHeader';
import { ReportDialogs } from './reports/dialogs/ReportDialogs';
import { ErrorDisplay } from './reports/error/ErrorDisplay';
import ReportsTable from './reports/ReportsTable';
import { LoadingSpinner } from './common/LoadingSpinner';
import { formatDate } from './reports/utils/dateUtils';

const ReportsList: FC = () => {
  const { reports, setReports, loading, error, refetch } = useReportsList();
  const {
    selectedReport,
    previewOpen,
    deleteDialogOpen,
    setPreviewOpen,
    setDeleteDialogOpen,
    setReportToDelete,
    handlePreviewReport,
    handleGeneratePDF,
    handleDeleteClick,
    handleDeleteConfirm,
    isDeleting
  } = useReportActions(reports, setReports, refetch); // Pass refetch function here

  if (loading || isDeleting) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorDisplay error={error} onRetry={refetch} />;
  }

  return (
    <>
      <ReportsLayout>
        <ReportsHeader />
        
        <ReportsTable
          reports={reports}
          onPreview={handlePreviewReport}
          onGeneratePDF={handleGeneratePDF}
          onDelete={handleDeleteClick}
          formatDate={formatDate}
        />
      </ReportsLayout>

      <ReportDialogs
        selectedReport={selectedReport}
        previewOpen={previewOpen}
        deleteDialogOpen={deleteDialogOpen}
        onPreviewClose={() => setPreviewOpen(false)}
        onDeleteClose={() => setDeleteDialogOpen(false)}
        onGeneratePDF={handleGeneratePDF}
        onDeleteConfirm={handleDeleteConfirm}
        setReportToDelete={setReportToDelete}
        isDeleting={isDeleting}
      />
    </>
  );
};

export default ReportsList;
