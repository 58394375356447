import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import Layout from './components/Layout';
import InspectionForm from './components/InspectionForm';
import ReportsList from './components/ReportsList';
import Settings from './components/Settings';
import IntroTemplate from './components/IntroTemplate';
import PdfPreview from './components/PdfPreview';
import { AuthProvider } from './contexts/AuthContext';
import { Login } from './components/auth/Login';
import { Signup } from './components/auth/Signup';
import { ForgotPassword } from './components/auth/ForgotPassword';
import { ProtectedRoute } from './components/common/ProtectedRoute';
import ProposalsList from './components/proposals/ProposalsList';
import ProposalForm from './components/proposals/ProposalForm';
import { InvoicesList, InvoiceForm } from './components/invoices';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            
            {/* Protected Routes */}
            <Route
              path="/*"
              element={
                <ProtectedRoute>
                  <Layout>
                    <Routes>
                      <Route path="/" element={<ReportsList />} />
                      <Route path="/new-inspection" element={<InspectionForm />} />
                      <Route path="/inspection/:id" element={<InspectionForm />} />
                      <Route path="/new-proposal" element={<ProposalForm />} />
                      <Route path="/proposal/:id" element={<ProposalForm />} />
                      <Route path="/proposals" element={<ProposalsList />} />
                      <Route path="/invoices" element={<InvoicesList />} />
                      <Route path="/invoices/new" element={<InvoiceForm />} />
                      <Route path="/invoice/:id" element={<InvoiceForm />} />
                      <Route path="/settings" element={<Settings />} />
                      <Route path="/intro-template" element={<IntroTemplate />} />
                      <Route path="/pdf-preview" element={<PdfPreview />} />
                    </Routes>
                  </Layout>
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
