import { Box, Button, CircularProgress } from '@mui/material';

interface TemplateActionsProps {
  onSave: () => void;
  isSaving: boolean;
}

export const TemplateActions = ({ onSave, isSaving }: TemplateActionsProps) => (
  <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end', mt: 3 }}>
    <Button 
      variant="contained" 
      onClick={onSave}
      disabled={isSaving}
      startIcon={isSaving ? <CircularProgress size={20} color="inherit" /> : null}
    >
      {isSaving ? 'Saving...' : 'Save Template'}
    </Button>
  </Box>
);
