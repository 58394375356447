import { Box } from '@mui/material';
import { BuildingComponentsSectionProps } from './types';
import { BuildingComponentField } from './building-components/BuildingComponentField';
import { SectionHeader } from './common/SectionHeader';
import { BUILDING_COMPONENT_FIELDS } from './building-components/constants';

const SECTION_TOOLTIP = 'Provide detailed descriptions and inspection requirements for each building component.';

const BuildingComponentsSection = ({
  buildingComponents,
  onFieldChange,
}: BuildingComponentsSectionProps) => {
  return (
    <Box>
      <SectionHeader 
        title="Building Components"
        tooltip={SECTION_TOOLTIP}
      />

      <Box display="flex" flexDirection="column" gap={3}>
        {BUILDING_COMPONENT_FIELDS.map((fieldKey) => (
          <BuildingComponentField
            key={fieldKey}
            fieldKey={fieldKey}
            value={buildingComponents[fieldKey]}
            onFieldChange={onFieldChange}
          />
        ))}
      </Box>
    </Box>
  );
};

export default BuildingComponentsSection;
