import { FC } from 'react';
import ReportPreview from '../ReportPreview';
import DeleteConfirmation from '../DeleteConfirmation';
import { InspectionReport } from '../../../services/types';
import { formatDate } from '../utils/dateUtils';

interface ReportDialogsProps {
  selectedReport: InspectionReport | null;
  previewOpen: boolean;
  deleteDialogOpen: boolean;
  onPreviewClose: () => void;
  onDeleteClose: () => void;
  onGeneratePDF: (report: InspectionReport) => void;
  onDeleteConfirm: () => void;
  setReportToDelete: (reportId: string | null) => void;
  isDeleting: boolean;
}

export const ReportDialogs: FC<ReportDialogsProps> = ({
  selectedReport,
  previewOpen,
  deleteDialogOpen,
  onPreviewClose,
  onDeleteClose,
  onGeneratePDF,
  onDeleteConfirm,
  setReportToDelete,
  isDeleting
}) => (
  <>
    <ReportPreview
      report={selectedReport}
      open={previewOpen}
      onClose={onPreviewClose}
      onGeneratePDF={onGeneratePDF}
      formatDate={formatDate}
    />

    <DeleteConfirmation
      open={deleteDialogOpen}
      onClose={() => {
        onDeleteClose();
        setReportToDelete(null);
      }}
      onConfirm={onDeleteConfirm}
      isDeleting={isDeleting}
    />
  </>
);
