import React from 'react';
import { Page, Text, View, Image } from '@react-pdf/renderer';
import { InspectionReport, Settings } from '../../types';
import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    position: 'relative',
    backgroundColor: '#FFFFFF',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  bluePanel: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    width: '40%',
    backgroundColor: '#1976D2',
    boxShadow: '2px 0 10px rgba(0, 0, 0, 0.1)',
  },
  coverBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.15,
  },
  logo: {
    position: 'absolute',
    top: 40,
    left: 40,
    width: '25%',
    height: 'auto',
    maxHeight: 80,
  },
  // Form fields in blue panel with enhanced styling
  formField: {
    marginBottom: 40,
    position: 'absolute',
    left: 40,
    right: '63%',
  },
  fieldLabel: {
    color: '#E3F2FD',
    fontSize: 10,
    fontFamily: 'Times-Roman',
    textTransform: 'uppercase',
    marginBottom: 5,
    letterSpacing: 1,
  },
  fieldValue: {
    color: '#FFFFFF',
    fontSize: 12,
    fontFamily: 'Times-Roman',
    borderBottom: '1pt solid rgba(255, 255, 255, 0.3)',
    paddingBottom: 4,
    lineHeight: 1.4,
  },
  // Title section in white area with enhanced styling
  titleContainer: {
    position: 'absolute',
    top: 160,
    left: '45%',
    right: 40,
  },
  title: {
    color: '#1976D2',
    fontSize: 42,
    fontFamily: 'Times-Bold',
    textTransform: 'uppercase',
    marginBottom: 8,
    letterSpacing: 2,
  },
  titleDivider: {
    width: 80,
    borderBottom: '3pt solid #1976D2',
    marginTop: 25,
    marginBottom: 25,
    opacity: 0.8,
  },
  subtitle: {
    color: '#424242',
    fontSize: 24,
    fontFamily: 'Times-Roman',
    marginTop: 20,
    letterSpacing: 1,
  },
  // Company info in white area with enhanced styling
  companyBox: {
    position: 'absolute',
    bottom: 40,
    left: '45%',
    right: 40,
    borderTop: '2pt solid #E3F2FD',
    paddingTop: 20,
    backgroundColor: '#FAFAFA',
    padding: 20,
    borderRadius: 4,
  },
  companyName: {
    color: '#1976D2',
    fontSize: 16,
    fontFamily: 'Times-Bold',
    marginBottom: 10,
    letterSpacing: 0.5,
  },
  companyDetails: {
    color: '#616161',
    fontSize: 11,
    fontFamily: 'Times-Roman',
    marginBottom: 3,
    lineHeight: 1.4,
  },
});

interface CoverPageProps {
  report: InspectionReport;
  settings: Settings;
}

export const CoverPage: React.FC<CoverPageProps> = ({ report, settings }) => {
  const formatDate = (date: string | number) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatAddress = (settings: Settings) => {
    const { address, city, state, zipCode } = settings.company;
    return `${address}, ${city}, ${state} ${zipCode}`;
  };

  return (
    <Page size="A4" style={styles.page}>
      {/* Background Elements */}
      <View style={styles.background}>
        {settings.company.coverBackground && (
          <Image 
            src={settings.company.coverBackground} 
            style={styles.coverBackground}
            cache={false}
          />
        )}
        <View style={styles.bluePanel} />
      </View>

      {/* Logo */}
      {settings.company.basicLogo && (
        <Image 
          src={settings.company.basicLogo}
          style={styles.logo}
          cache={false}
        />
      )}

      {/* Form Fields */}
      <View style={[styles.formField, { top: 160 }]}>
        <Text style={styles.fieldLabel}>Property Address</Text>
        <Text style={styles.fieldValue}>{report.propertyAddress}</Text>
      </View>

      <View style={[styles.formField, { top: 240 }]}>
        <Text style={styles.fieldLabel}>Client</Text>
        <Text style={styles.fieldValue}>{report.clientName}</Text>
      </View>

      <View style={[styles.formField, { top: 320 }]}>
        <Text style={styles.fieldLabel}>Inspection Date</Text>
        <Text style={styles.fieldValue}>
          {formatDate(report.inspectionDate || Date.now())}
        </Text>
      </View>

      {/* Title */}
      <View style={styles.titleContainer}>
        <Text style={styles.title}>Building</Text>
        <Text style={styles.title}>Envelope</Text>
        <View style={styles.titleDivider} />
        <Text style={styles.subtitle}>Inspection Report</Text>
      </View>

      {/* Company Info */}
      <View style={styles.companyBox}>
        <Text style={styles.companyName}>{settings.company.companyName}</Text>
        <Text style={styles.companyDetails}>{formatAddress(settings)}</Text>
        {settings.company.phone && (
          <Text style={styles.companyDetails}>{settings.company.phone}</Text>
        )}
        {settings.company.email && (
          <Text style={styles.companyDetails}>{settings.company.email}</Text>
        )}
      </View>
    </Page>
  );
};
