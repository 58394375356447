import { Box } from '@mui/material';
import FormTextField from './FormTextField';
import { SectionHeader } from './common/SectionHeader';

interface ProposalInformationSectionProps {
  proposalInformation: string;
  onFieldChange: (field: string, value: string) => void;
}

const SECTION_TOOLTIP = 'Enter proposal information and details.';

const ProposalInformationSection = ({
  proposalInformation,
  onFieldChange,
}: ProposalInformationSectionProps) => {
  return (
    <Box>
      <SectionHeader 
        title="Proposal Information"
        tooltip={SECTION_TOOLTIP}
      />
      <FormTextField
        rows={4}
        label="Proposal Information"
        placeholder="Enter proposal information details"
        value={proposalInformation}
        onChange={(value) => onFieldChange('proposalInformation', value)}
      />
    </Box>
  );
};

export default ProposalInformationSection;
