import { useState, useCallback } from 'react';
import { IntroTemplateData } from '../types';
import { defaultTemplate } from '../constants/defaultTemplate';

export const useTemplateState = () => {
  const [template, setTemplate] = useState<IntroTemplateData>(defaultTemplate);

  const handleChange = useCallback((field: string, value: string) => {
    if (field.includes('.')) {
      const [section, subfield] = field.split('.');
      setTemplate(prev => {
        if (section === 'buildingComponents') {
          return {
            ...prev,
            buildingComponents: {
              ...prev.buildingComponents,
              [subfield]: value
            }
          };
        } else if (section === 'causesOfDistress') {
          return {
            ...prev,
            causesOfDistress: {
              ...prev.causesOfDistress,
              [subfield]: value
            }
          };
        }
        return prev;
      });
    } else {
      setTemplate(prev => ({
        ...prev,
        [field]: value
      }));
    }
  }, []);

  return {
    template,
    setTemplate,
    handleChange
  };
};
