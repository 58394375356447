import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography, Paper, Box } from '@mui/material';
import { overallConditionLevels } from './constants';
import { OverallConditionLevel } from './types';

interface OverallConditionProps {
  value: OverallConditionLevel | undefined;
  onChange: (value: OverallConditionLevel) => void;
}

const OverallCondition: React.FC<OverallConditionProps> = ({ value, onChange }) => {
  const handleChange = (event: { target: { value: unknown } }) => {
    onChange(event.target.value as OverallConditionLevel);
  };

  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
      <Typography variant="h6" gutterBottom>
        Overall Building Condition Assessment
      </Typography>
      
      <FormControl fullWidth sx={{ mb: 4 }}>
        <InputLabel id="overall-condition-label">Overall Condition Rating</InputLabel>
        <Select
          labelId="overall-condition-label"
          id="overall-condition"
          value={value || ''}
          label="Overall Condition Rating"
          onChange={handleChange}
        >
          {Object.entries(overallConditionLevels).map(([key, { label }]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          Select the overall condition rating that best describes the building envelope
        </FormHelperText>
      </FormControl>

      {value && (
        <Paper elevation={1} sx={{ p: 3, bgcolor: 'background.default' }}>
          <Typography variant="subtitle1" gutterBottom fontWeight="bold">
            Condition Description
          </Typography>
          <Typography variant="body1">
            {overallConditionLevels[value].description}
          </Typography>
        </Paper>
      )}
    </Box>
  );
};

export default OverallCondition;
