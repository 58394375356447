import { FC, useEffect, useState, useCallback } from 'react';
import { collection, query, getDocs, orderBy, deleteDoc, doc, getDoc, limit, startAfter } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { LoadingSpinner } from '../common/LoadingSpinner';
import InvoicesLayout from './layout/InvoicesLayout';
import InvoicesHeader from './header/InvoicesHeader';
import InvoicesTable from './InvoicesTable';
import { formatDate } from '../reports/utils/dateUtils';
import { Invoice } from './types';
import { Button, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { generateInvoicePdfBlob, downloadInvoicePdf } from '../../services/pdf/utils/invoicePdfUtils';
import InvoicePreviewDialog from './InvoicePreviewDialog';

const INVOICES_PER_PAGE = 10;

const InvoicesList: FC = () => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [previewInvoice, setPreviewInvoice] = useState<Invoice | null>(null);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const location = useLocation();

  const fetchInvoices = async (lastDoc?: any) => {
    const invoicesRef = collection(db, 'invoices');
    let q = query(
      invoicesRef,
      orderBy('createdAt', 'desc'),
      limit(INVOICES_PER_PAGE)
    );

    if (lastDoc) {
      q = query(
        invoicesRef,
        orderBy('createdAt', 'desc'),
        startAfter(lastDoc),
        limit(INVOICES_PER_PAGE)
      );
    }

    const querySnapshot = await getDocs(q);
    
    // Set last visible document for pagination
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLastVisible(lastVisible);
    
    // Check if there are more results
    setHasMore(querySnapshot.docs.length === INVOICES_PER_PAGE);
    
    return querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    })) as Invoice[];
  };

  const loadMoreInvoices = async () => {
    if (!hasMore || loadingMore) return;
    
    try {
      setLoadingMore(true);
      const moreInvoices = await fetchInvoices(lastVisible);
      setInvoices(prev => [...prev, ...moreInvoices]);
    } catch (error) {
      console.error('Error loading more invoices:', error);
      setError('Error loading more invoices');
    } finally {
      setLoadingMore(false);
    }
  };

  const refreshData = useCallback(async () => {
    try {
      setLoading(true);
      const invoicesData = await fetchInvoices();
      setInvoices(invoicesData);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error loading data');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    refreshData();
  }, [refreshData, location]);

  const handlePreview = async (invoice: Invoice) => {
    try {
      const invoiceDoc = await getDoc(doc(db, 'invoices', invoice.id));
      const fullInvoice = { ...invoiceDoc.data(), id: invoiceDoc.id } as Invoice;
      setPreviewInvoice(fullInvoice);
    } catch (error) {
      console.error('Error loading invoice for preview:', error);
      alert('Error loading invoice preview');
    }
  };

  const handleGeneratePDF = async (invoice: Invoice) => {
    try {
      const blob = await generateInvoicePdfBlob(invoice);
      await downloadInvoicePdf(blob, invoice.invoiceNumber);
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating PDF');
    }
  };

  const handleDelete = async (invoice: Invoice) => {
    if (window.confirm('Are you sure you want to delete this invoice?')) {
      try {
        await deleteDoc(doc(db, 'invoices', invoice.id));
        setInvoices(invoices.filter(i => i.id !== invoice.id));
      } catch (error) {
        console.error('Error deleting invoice:', error);
        alert('Error deleting invoice');
      }
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Error loading invoices</div>;
  }

  return (
    <InvoicesLayout>
      <InvoicesHeader />
      <InvoicesTable
        invoices={invoices}
        onPreview={handlePreview}
        onGeneratePDF={handleGeneratePDF}
        onDelete={handleDelete}
        formatDate={formatDate}
      />
      
      {hasMore && (
        <Box display="flex" justifyContent="center" my={2}>
          <Button 
            variant="contained" 
            onClick={loadMoreInvoices}
            disabled={loadingMore}
          >
            {loadingMore ? 'Loading...' : 'Load More'}
          </Button>
        </Box>
      )}

      <InvoicePreviewDialog
        invoice={previewInvoice}
        onClose={() => setPreviewInvoice(null)}
      />
    </InvoicesLayout>
  );
};

export default InvoicesList;
