import React, { useState } from 'react';
import { Box, Typography, Grid, useTheme, useMediaQuery } from '@mui/material';
import PhotoCard from './PhotoCard';
import type { PhotoDetails } from '../types';
import Toast from '../../common/Toast';
import useToast from '../../../hooks/useToast';

interface PhotoListProps {
  photos: PhotoDetails[];
  onPhotoUpdate: (photo: PhotoDetails) => void;
  onValidationChange?: (isValid: boolean) => void;
}

const PhotoList: React.FC<PhotoListProps> = ({ photos, onPhotoUpdate, onValidationChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { toast, showToast, hideToast } = useToast();
  const [unsavedPhotoIds, setUnsavedPhotoIds] = useState<Set<string>>(new Set());

  const handlePhotoUpdate = (photo: PhotoDetails) => {
    onPhotoUpdate(photo);
    setUnsavedPhotoIds(prev => {
      const newSet = new Set(prev);
      newSet.delete(photo.id);
      return newSet;
    });
    showToast('Photo details updated', 'success');
    
    // Notify parent about validation state after save
    if (onValidationChange) {
      const remainingUnsaved = unsavedPhotoIds.size > 1 || (unsavedPhotoIds.size === 1 && !unsavedPhotoIds.has(photo.id));
      onValidationChange(!remainingUnsaved);
    }
  };

  const handleUnsavedChanges = (photoId: string, hasChanges: boolean) => {
    setUnsavedPhotoIds(prev => {
      const newSet = new Set(prev);
      if (hasChanges) {
        newSet.add(photoId);
      } else {
        newSet.delete(photoId);
      }
      return newSet;
    });
    
    // Notify parent about validation state
    if (onValidationChange) {
      onValidationChange(hasChanges ? false : unsavedPhotoIds.size <= 1);
    }
  };

  if (photos.length === 0) return null;

  return (
    <Box>
      <Typography 
        variant={isMobile ? "subtitle1" : "h6"} 
        gutterBottom 
        sx={{ 
          mt: 3, 
          mb: 2,
          fontSize: isMobile ? '1.1rem' : undefined
        }}
      >
        Uploaded Photos ({photos.length})
      </Typography>
      <Grid container spacing={isMobile ? 2 : 3}>
        {photos.map((photo) => (
          <Grid item xs={12} key={photo.id}>
            <PhotoCard
              photo={photo}
              onUpdate={handlePhotoUpdate}
              onHasUnsavedChanges={(hasChanges) => handleUnsavedChanges(photo.id, hasChanges)}
            />
          </Grid>
        ))}
      </Grid>
      <Toast 
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={hideToast}
      />
    </Box>
  );
};

export default PhotoList;
