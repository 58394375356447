import { useState } from 'react';
import { BuildingComponentFieldProps } from '../types';
import FormTextField from '../FormTextField';
import { FIELD_CONFIG } from './constants';

export const BuildingComponentField = ({ fieldKey, value, onFieldChange }: BuildingComponentFieldProps) => {
  const [error, setError] = useState<string | null>(null);
  const config = FIELD_CONFIG[fieldKey];
  
  const handleChange = (newValue: string) => {
    if (config.validation && !config.validation(newValue)) {
      setError(config.errorMessage || 'Invalid input');
    } else {
      setError(null);
    }
    onFieldChange(`buildingComponents.${fieldKey}`, newValue);
  };

  return (
    <FormTextField
      rows={4}
      label={config.label}
      placeholder={config.placeholder}
      value={value}
      onChange={handleChange}
      error={!!error}
      helperText={error}
    />
  );
};
