import { Box, Button } from '@mui/material';

interface FormNavigationProps {
  activeStep: number;
  totalSteps: number;
  onBack: () => void;
  onNext: () => void;
  onSubmit: (e: React.FormEvent) => void;
  saving: boolean;
  isStepValid?: boolean; // Make isStepValid optional with a default value
}

const FormNavigation = ({
  activeStep,
  totalSteps,
  onBack,
  onNext,
  onSubmit,
  saving,
  isStepValid = true // Default to true if not provided
}: FormNavigationProps) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
      <Button
        variant="contained"
        onClick={onBack}
        disabled={activeStep === 0 || saving}
      >
        Back
      </Button>
      {activeStep === totalSteps - 1 ? (
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={saving || !isStepValid}
        >
          {saving ? 'Saving...' : 'Complete Report'}
        </Button>
      ) : (
        <Button
          variant="contained"
          onClick={onNext}
          color="primary"
          disabled={saving || !isStepValid}
        >
          {saving ? 'Saving...' : 'Next'}
        </Button>
      )}
    </Box>
  );
};

export default FormNavigation;
