import { TextField, TextFieldProps } from '@mui/material';

interface FormTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (value: string) => void;
}

const FormTextField = ({ onChange, ...props }: FormTextFieldProps) => {
  return (
    <TextField
      {...props}
      fullWidth
      multiline
      onChange={(e) => onChange(e.target.value)}
      sx={{ mb: 2, ...props.sx }}
    />
  );
};

export default FormTextField;
