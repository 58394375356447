import { Box, Button, Paper, Typography } from '@mui/material';
import { ErrorDisplayProps } from './types';

export const ErrorDisplay = ({ error, onRetry, report }: ErrorDisplayProps) => (
  <Box 
    p={3} 
    display="flex" 
    flexDirection="column" 
    alignItems="center"
    gap={2}
  >
    <Typography color="error" variant="h6">
      Error Generating Preview
    </Typography>
    <Paper elevation={3} sx={{ p: 2, bgcolor: 'error.light', color: 'error.contrastText' }}>
      <Typography>{error}</Typography>
    </Paper>
    <Button 
      variant="contained" 
      color="primary" 
      onClick={() => {
        console.log('Retrying preview generation with report:', {
          id: report.id,
          photoCount: report.photos?.length || 0,
          photos: report.photos,
          buildingSections: Object.keys(report.buildingSections || {})
        });
        onRetry();
      }}
    >
      Retry
    </Button>
  </Box>
);
