import { IntroTemplateData } from '../types';

export const defaultTemplate: IntroTemplateData = {
  reportPurpose: '',
  scope: '',
  exclusiveUse: '',
  reportLimitations: '',
  photographs: '',
  thirdPartyInterpretation: '',
  generalConditions: '',
  warrantyDisclaimer: '',
  buildingCodeCompliance: '',
  description: '',
  buildingEnvelopeInspection: '',
  buildingEnvelopeReport: '',
  generalConditionsSection: '',
  causesOfDistress: {
    installationError: '',
    inappropriateMaterials: '',
    componentDamage: '',
    inadequateDesign: '',
    exposure: '',
    buildingMovement: '',
    organisms: '',
    materialProperties: ''
  },
  importance: '',
  resolution: '',
  buildingComponents: {
    roofing: '',
    siding: '',
    windowsAndDoors: '',
    decking: '',
    foundation: ''
  },
  proposalInformation: ''
};
