import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Template } from '../../../types';
import { styles } from '../../styles/pdfStyles';

interface ReportInformationSectionProps {
  template: Template;
}

export const ReportInformationSection: React.FC<ReportInformationSectionProps> = ({ template }) => {
  const sections = [
    { title: 'Purpose', content: template.reportPurpose },
    { title: 'Scope', content: template.scope },
    { title: 'Exclusive Use', content: template.exclusiveUse },
    { title: 'Report Limitations', content: template.reportLimitations },
    { title: 'Photographs', content: template.photographs },
    { title: 'Third Party Interpretation', content: template.thirdPartyInterpretation },
    { title: 'Building Code Compliance', content: template.buildingCodeCompliance },
  ];

  return (
    <View style={styles.section}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>Report Information</Text>
      </View>

      {/* Content Container */}
      <View style={[styles.contentBlock, { marginTop: 10 }]}>
        {sections.map((section, index) => (
          <View 
            key={section.title}
            style={[
              {
                marginBottom: index === sections.length - 1 ? 0 : 15,
                backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F5F5F5',
                padding: 10,
                borderRadius: 4,
              }
            ]}
          >
            {/* Section Title */}
            <Text style={{
              fontSize: 14,
              fontFamily: 'Times-Bold',
              color: '#1976D2',
              marginBottom: 6,
            }}>
              {section.title}
            </Text>

            {/* Section Content */}
            <Text style={{
              fontSize: 12,
              color: '#424242',
              lineHeight: 1.4,
            }}>
              {section.content || 'Not specified'}
            </Text>
          </View>
        ))}
      </View>

      {/* Optional Note */}
      {!sections.some(section => section.content) && (
        <Text style={[styles.text, { 
          fontStyle: 'italic',
          textAlign: 'center',
          marginTop: 8,
          color: '#616161'
        }]}>
          No report information has been specified.
        </Text>
      )}
    </View>
  );
};
