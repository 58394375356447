import { useState, ChangeEvent } from 'react';
import { storage, db, auth } from '../../../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { PhotoDetails, InspectionData } from '../types';

interface PhotoUploadState {
  isUploading: boolean;
  progress: number;
  error: string | null;
}

export const usePhotoUpload = (
  formData: InspectionData,
  setFormData: React.Dispatch<React.SetStateAction<InspectionData>>,
  reportId?: string
) => {
  const [state, setState] = useState<PhotoUploadState>({
    isUploading: false,
    progress: 0,
    error: null
  });

  const handlePhotoUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;
    
    const file = e.target.files[0];
    setState(prev => ({ ...prev, isUploading: true, error: null }));

    try {
      if (!auth.currentUser) {
        throw new Error('Authentication required to upload photos');
      }

      const fileId = uuidv4();
      const fileExtension = file.name.split('.').pop();
      const fileName = `${fileId}.${fileExtension}`;
      // If no reportId, store in a temporary location
      const storagePath = reportId 
        ? `reports/${reportId}/photos/${fileName}`
        : `temp_photos/${auth.currentUser.uid}/${fileName}`;
      const storageRef = ref(storage, storagePath);

      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      // Create new photo with required fields
      const newPhoto: PhotoDetails = {
        id: fileId,
        url: downloadURL,
        description: '',
        buildingSection: '',
        severityLevel: 'informational',
        recommendations: '',
        uploadedAt: new Date().toISOString(),
        findings: '',
        estimatedCost: '',
        location: ''
      };

      setFormData(prevData => ({
        ...prevData,
        photos: [...(prevData.photos || []), newPhoto]
      }));

      setState(prev => ({ ...prev, isUploading: false, progress: 100 }));
      return downloadURL;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to upload photo';
      setState(prev => ({ ...prev, error: errorMessage, isUploading: false }));
      throw error;
    }
  };

  const handlePhotoUpdate = async (updatedPhoto: PhotoDetails) => {
    const photoIndex = formData.photos.findIndex(p => p.id === updatedPhoto.id);
    if (photoIndex === -1) return;

    const updatedPhotos = [...formData.photos];
    updatedPhotos[photoIndex] = updatedPhoto;

    setFormData(prevData => ({
      ...prevData,
      photos: updatedPhotos
    }));

    // Only update Firestore if we have a reportId
    if (reportId) {
      try {
        if (!auth.currentUser) {
          throw new Error('Authentication required to update photos');
        }

        const reportRef = doc(db, 'inspections', reportId);
        await updateDoc(reportRef, {
          photos: updatedPhotos
        });
      } catch (error) {
        console.error('Error updating photo details:', error);
        throw error;
      }
    }
  };

  return {
    uploading: state.isUploading,
    progress: state.progress,
    error: state.error,
    handlePhotoUpload,
    handlePhotoUpdate
  };
};
