import React from 'react';
import { View, Text, Image, Styles } from '@react-pdf/renderer';
import { styles } from '../styles/pdfStyles';
import { formatCurrency } from '../../../utils/numberFormat';
import { PhotoDetails } from '../../../components/inspection-form/types';

interface PhotoDetailsProps {
  photo: PhotoDetails;
}

interface SeverityStyle {
  background: Styles;
  text: Styles;
}

const PhotoDetailsSection: React.FC<PhotoDetailsProps> = ({ photo }) => {
  // Helper function to get severity-specific styles
  const getSeverityStyles = (severity: string): SeverityStyle => {
    const severityLower = severity.toLowerCase();
    switch (severityLower) {
      case 'critical':
        return {
          background: styles.severityBoxCritical as Styles,
          text: styles.severityCritical as Styles
        };
      case 'moderate':
        return {
          background: styles.severityBoxModerate as Styles,
          text: styles.severityModerate as Styles
        };
      case 'informational':
      case 'low':
        return {
          background: styles.severityBoxInformational as Styles,
          text: styles.severityInformational as Styles
        };
      default:
        return {
          background: styles.severityBoxInformational as Styles,
          text: styles.severityInformational as Styles
        };
    }
  };

  const severityStyles = photo.severityLevel ? getSeverityStyles(photo.severityLevel) : null;

  return (
    <View style={styles.photoSection}>
      {/* Photo Section Title */}
      <Text style={styles.photoSectionTitle}>
        Photo Details
      </Text>

      <View style={styles.photoDetailsBlock}>
        {/* Severity Level - if exists, show at the top with appropriate styling */}
        {photo.severityLevel && severityStyles && (
          <View style={[styles.severityBox, severityStyles.background, { marginBottom: 10 } as Styles]}>
            <Text style={[styles.severityLabel, severityStyles.text]}>
              {photo.severityLevel.toUpperCase()} SEVERITY
            </Text>
          </View>
        )}

        {/* Photo Image */}
        <Image
          src={photo.url}
          style={styles.photo}
        />
        
        {/* Location */}
        {photo.location && (
          <View style={styles.photoDetailRow}>
            <Text style={styles.photoDetailLabel}>Location:</Text>
            <Text style={styles.photoDetailText}>{photo.location}</Text>
          </View>
        )}
        
        {/* Findings */}
        {photo.findings && (
          <View style={styles.photoDetailRow}>
            <Text style={styles.photoDetailLabel}>Findings:</Text>
            <Text style={styles.photoDetailText}>{photo.findings}</Text>
          </View>
        )}
        
        {/* Recommendations */}
        {photo.recommendations && (
          <View style={styles.photoDetailRow}>
            <Text style={styles.photoDetailLabel}>Recommendations:</Text>
            <Text style={styles.photoDetailText}>{photo.recommendations}</Text>
          </View>
        )}
        
        {/* Estimated Cost */}
        {photo.estimatedCost && (
          <View style={[styles.totalCostRow, { marginTop: 15 } as Styles]}>
            <Text style={styles.photoDetailLabel}>Estimated Cost:</Text>
            <Text style={[styles.photoDetailText, { fontFamily: 'Times-Bold' } as Styles]}>
              ${formatCurrency(photo.estimatedCost)}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default PhotoDetailsSection;
