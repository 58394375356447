import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Template } from '../../../types';
import { styles } from '../../styles/pdfStyles';

interface BuildingEnvelopeSectionProps {
  template: Template;
}

export const BuildingEnvelopeSection: React.FC<BuildingEnvelopeSectionProps> = ({ template }) => {
  const sections = [
    {
      title: 'Description',
      content: template.description,
      icon: '🏢'
    },
    {
      title: 'Building Envelope Inspection',
      content: template.buildingEnvelopeInspection,
      icon: '🔍'
    },
    {
      title: 'Building Envelope Report',
      content: template.buildingEnvelopeReport,
      icon: '📋'
    }
  ];

  return (
    <View style={styles.section}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>Building Envelope Overview</Text>
      </View>

      {/* Content Container */}
      <View style={[styles.contentBlock, { marginTop: 15 }]}>
        {sections.map((section, index) => (
          <View 
            key={section.title}
            style={[
              {
                marginBottom: index === sections.length - 1 ? 0 : 20,
                backgroundColor: '#FFFFFF',
                padding: 15,
                borderRadius: 4,
                borderBottom: index !== sections.length - 1 ? '1pt solid #E0E0E0' : 'none',
              }
            ]}
          >
            {/* Section Header with Gradient Background */}
            <View style={{
              backgroundColor: '#E3F2FD',
              padding: 10,
              borderRadius: 4,
              marginBottom: 12,
              borderLeft: '4pt solid #1976D2',
            }}>
              <Text style={{
                fontSize: 16,
                fontFamily: 'Times-Bold',
                color: '#1976D2',
              }}>
                {section.title}
              </Text>
            </View>

            {/* Section Content */}
            <View style={{
              backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#FAFAFA',
              padding: 10,
              borderRadius: 4,
            }}>
              <Text style={{
                fontSize: 12,
                color: '#424242',
                lineHeight: 1.5,
                textAlign: 'justify',
              }}>
                {section.content || 'Not specified'}
              </Text>
            </View>
          </View>
        ))}
      </View>

      {/* Optional Note */}
      {!sections.some(section => section.content) && (
        <Text style={[styles.text, { 
          fontStyle: 'italic',
          textAlign: 'center',
          marginTop: 10,
          color: '#616161'
        }]}>
          No building envelope information has been specified.
        </Text>
      )}
    </View>
  );
};
