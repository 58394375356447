import { Box, Typography } from '@mui/material';
import FormTextField from './FormTextField';

interface BuildingEnvelopeInspectionSectionProps {
  buildingEnvelopeInspection: string;
  onFieldChange: (field: string, value: string) => void;
}

const BuildingEnvelopeInspectionSection = ({
  buildingEnvelopeInspection,
  onFieldChange,
}: BuildingEnvelopeInspectionSectionProps) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Building Envelope Inspection
      </Typography>
      <FormTextField
        rows={4}
        label="Building Envelope Inspection"
        placeholder="Enter building envelope inspection details"
        value={buildingEnvelopeInspection}
        onChange={(value) => onFieldChange('buildingEnvelopeInspection', value)}
      />
    </Box>
  );
};

export default BuildingEnvelopeInspectionSection;
