import { Stepper, Step, StepLabel } from '@mui/material';

interface FormStepperProps {
  activeStep: number;
  steps: string[];
}

const FormStepper = ({ activeStep, steps }: FormStepperProps) => {
  return (
    <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default FormStepper;
