import { StepContentProps } from './types';
import BasicInformation from './BasicInformation';
import PhotoUpload from './photos/PhotoUpload';
import GeneralNotes from './GeneralNotes';

export const StepContent = ({
  activeStep,
  formData,
  uploading,
  handleChange,
  handlePhotoUpload,
  handlePhotoUpdate,
  setFormData,
  onStepValidationChange
}: StepContentProps) => {
  switch (activeStep) {
    case 0:
      return (
        <BasicInformation
          clientName={formData.clientName}
          propertyAddress={formData.propertyAddress}
          inspectionDate={formData.inspectionDate}
          propertyType={formData.propertyType}
          onBasicInfoChange={handleChange}
          onPropertyTypeChange={(e) => setFormData(prev => ({ ...prev, propertyType: e.target.value as string }))}
        />
      );
    case 1:
      return (
        <PhotoUpload
          photos={formData.photos}
          uploading={uploading}
          onPhotoUpload={handlePhotoUpload}
          onPhotoUpdate={handlePhotoUpdate}
          onValidationChange={onStepValidationChange}
        />
      );
    case 2:
      return (
        <GeneralNotes
          value={formData.generalNotes}
          onChange={handleChange}
          overallCondition={formData.overallCondition}
          onOverallConditionChange={(value) => setFormData(prev => ({ ...prev, overallCondition: value }))}
        />
      );
    default:
      return null;
  }
};
