import { useState } from 'react';
import { UseFormStepsProps, UseFormStepsReturn } from '../types';
import { getStepValidation, getStepErrorMessage } from '../utils/validation';

export const useFormSteps = ({ formData, saveProgress }: UseFormStepsProps): UseFormStepsReturn => {
  const [activeStep, setActiveStep] = useState(0);
  const [photoStepValid, setPhotoStepValid] = useState(true);

  const handleNext = async () => {
    // For photo step, check both standard validation and photo-specific validation
    const standardValidation = getStepValidation(activeStep, formData);
    const isValid = activeStep === 1 ? (standardValidation && photoStepValid) : standardValidation;
    
    if (!isValid) {
      const message = activeStep === 1 && !photoStepValid 
        ? 'Please save any unsaved changes before proceeding.'
        : getStepErrorMessage(activeStep);
      alert(message);
      return;
    }

    const saved = await saveProgress();
    if (saved) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = async () => {
    // For photo step, check if there are unsaved changes
    if (activeStep === 1 && !photoStepValid) {
      alert('Please save any unsaved changes before going back.');
      return;
    }

    const saved = await saveProgress();
    if (saved) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleStepValidation = (isValid: boolean) => {
    if (activeStep === 1) {
      setPhotoStepValid(isValid);
    }
  };

  const isStepValid = activeStep === 1 
    ? (getStepValidation(activeStep, formData) && photoStepValid)
    : getStepValidation(activeStep, formData);

  return {
    activeStep,
    handleNext,
    handleBack,
    isStepValid,
    onStepValidationChange: handleStepValidation
  };
};
