import { Box, Button, Typography } from '@mui/material';
import { PreviewHeaderProps } from './types';

export const PreviewHeader = ({ onGeneratePDF, loading }: PreviewHeaderProps) => (
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Typography variant="h6">Report Preview</Typography>
    <Button
      variant="contained"
      color="primary"
      onClick={onGeneratePDF}
      disabled={loading}
    >
      Download PDF
    </Button>
  </Box>
);
