import { Grid } from '@mui/material';
import FormTextField from '../common/FormTextField';
import OverallCondition from './OverallCondition';
import { OverallConditionLevel } from './types';

interface GeneralNotesProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  overallCondition: OverallConditionLevel | undefined;
  onOverallConditionChange: (value: OverallConditionLevel) => void;
}

const GeneralNotes = ({ value, onChange, overallCondition, onOverallConditionChange }: GeneralNotesProps) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <OverallCondition 
          value={overallCondition}
          onChange={onOverallConditionChange}
        />
      </Grid>
      <Grid item xs={12}>
        <FormTextField
          multiline
          rows={4}
          label="General Notes"
          name="generalNotes"
          value={value}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  );
};

export default GeneralNotes;
