import { FC } from 'react';
import { Alert, Button } from '@mui/material';

interface ErrorDisplayProps {
  error: string;
  onRetry: () => void;
}

export const ErrorDisplay: FC<ErrorDisplayProps> = ({ error, onRetry }) => (
  <Alert 
    severity="error" 
    action={
      <Button color="inherit" size="small" onClick={onRetry}>
        Retry
      </Button>
    }
  >
    Error loading reports: {error}
  </Alert>
);
