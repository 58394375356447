import { FC } from 'react';
import { Typography, Paper, Container, Card, CardContent, Divider } from '@mui/material';
import { useIntroTemplate } from './intro-template/hooks/useIntroTemplate';
import ReportInformationSection from './intro-template/ReportInformationSection';
import BuildingEnvelopeInspectionSection from './intro-template/BuildingEnvelopeInspectionSection';
import BuildingEnvelopeReportSection from './intro-template/BuildingEnvelopeReportSection';
import GeneralConditionsSection from './intro-template/GeneralConditionsSection';
import CausesOfDistressSection from './intro-template/CausesOfDistressSection';
import BuildingComponentsSection from './intro-template/BuildingComponentsSection';
import ProposalInformationSection from './intro-template/ProposalInformationSection';
import FormTextField from './intro-template/FormTextField';
import { LoadingSpinner } from './common/LoadingSpinner';
import { TemplateActions } from './intro-template/TemplateActions';
import { FeedbackSnackbars } from './intro-template/feedback/FeedbackSnackbars';

const IntroTemplate: FC = () => {
  const {
    // Template state
    template,
    handleChange,

    // Loading states
    isLoading,
    isSaving,

    // Feedback states
    error,
    success,
    clearError,
    clearSuccess,

    // Operations
    handleSave
  } = useIntroTemplate();

  if (isLoading) {
    return (
      <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '60vh' }}>
        <LoadingSpinner />
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Introduction Template
      </Typography>
      <Paper elevation={3} sx={{ p: 3 }}>
        <Card>
          <CardContent>
            <ReportInformationSection
              {...template}
              onFieldChange={handleChange}
            />

            <Divider sx={{ my: 3 }} />

            <BuildingEnvelopeInspectionSection
              buildingEnvelopeInspection={template.buildingEnvelopeInspection}
              onFieldChange={handleChange}
            />

            <Divider sx={{ my: 3 }} />

            <CausesOfDistressSection
              causesOfDistress={template.causesOfDistress}
              onFieldChange={handleChange}
            />

            <FormTextField
              rows={4}
              label="Importance - Annual Inspection"
              placeholder="Enter importance and annual inspection details"
              value={template.importance}
              onChange={(value) => handleChange('importance', value)}
            />

            <FormTextField
              rows={4}
              label="Resolution - Resolving Building Envelope Issues"
              placeholder="Enter resolution details"
              value={template.resolution}
              onChange={(value) => handleChange('resolution', value)}
            />

            <Divider sx={{ my: 3 }} />

            <BuildingComponentsSection
              buildingComponents={template.buildingComponents}
              onFieldChange={handleChange}
            />

            <Divider sx={{ my: 3 }} />

            <BuildingEnvelopeReportSection
              buildingEnvelopeReport={template.buildingEnvelopeReport}
              onFieldChange={handleChange}
            />

            <Divider sx={{ my: 3 }} />

            <ProposalInformationSection
              proposalInformation={template.proposalInformation}
              onFieldChange={handleChange}
            />

            <Divider sx={{ my: 3 }} />

            <GeneralConditionsSection
              generalConditionsSection={template.generalConditionsSection}
              onFieldChange={handleChange}
            />

            <TemplateActions
              onSave={handleSave}
              isSaving={isSaving}
            />
          </CardContent>
        </Card>
      </Paper>

      <FeedbackSnackbars
        success={success}
        error={error}
        onSuccessClose={clearSuccess}
        onErrorClose={clearError}
      />
    </Container>
  );
};

export default IntroTemplate;
