import { FC, ReactNode } from 'react';
import { Paper } from '@mui/material';

interface ReportsLayoutProps {
  children: ReactNode;
}

export const ReportsLayout: FC<ReportsLayoutProps> = ({ children }) => (
  <Paper elevation={3} sx={{ p: 4 }}>
    {children}
  </Paper>
);
