import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { styles } from '../../styles/pdfStyles';
import PhotoDetailsSection from '../PhotoDetailsSection';
import { InspectionReport } from '../../../types';

interface PhotoDocumentationSectionProps {
  report: InspectionReport;
}

const PhotoDocumentationSection: React.FC<PhotoDocumentationSectionProps> = ({ report }) => {
  return (
    <View style={styles.section}>
      <Text style={styles.sectionTitle}>Photo Documentation</Text>
      {report.photos && report.photos.length > 0 ? (
        report.photos.map((photo, index) => (
          <View key={index} style={styles.photoContainer}>
            <PhotoDetailsSection photo={photo} />
          </View>
        ))
      ) : (
        <Text style={styles.noPhotosText}>No photos available.</Text>
      )}
    </View>
  );
};

export default PhotoDocumentationSection;
