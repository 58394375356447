import { Typography, Grid } from '@mui/material';
import { CausesOfDistress } from '../inspection-form/types';
import FormTextField from './FormTextField';

interface CausesOfDistressSectionProps {
  causesOfDistress: CausesOfDistress;
  onFieldChange: (field: string, value: string) => void;
}

const CausesOfDistressSection = ({
  causesOfDistress,
  onFieldChange,
}: CausesOfDistressSectionProps) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Causes of Distress and Failure
      </Typography>

      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <FormTextField
            rows={3}
            label="Installation Error"
            placeholder="Enter installation error details"
            value={causesOfDistress.installationError}
            onChange={(value) => onFieldChange('causesOfDistress.installationError', value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            rows={3}
            label="Inappropriate Materials"
            placeholder="Enter inappropriate materials details"
            value={causesOfDistress.inappropriateMaterials}
            onChange={(value) => onFieldChange('causesOfDistress.inappropriateMaterials', value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            rows={3}
            label="Component Damage"
            placeholder="Enter component damage details"
            value={causesOfDistress.componentDamage}
            onChange={(value) => onFieldChange('causesOfDistress.componentDamage', value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            rows={3}
            label="Inadequate Design"
            placeholder="Enter inadequate design details"
            value={causesOfDistress.inadequateDesign}
            onChange={(value) => onFieldChange('causesOfDistress.inadequateDesign', value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            rows={3}
            label="Exposure"
            placeholder="Enter exposure details"
            value={causesOfDistress.exposure}
            onChange={(value) => onFieldChange('causesOfDistress.exposure', value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            rows={3}
            label="Building Movement"
            placeholder="Enter building movement details"
            value={causesOfDistress.buildingMovement}
            onChange={(value) => onFieldChange('causesOfDistress.buildingMovement', value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            rows={3}
            label="Organisms"
            placeholder="Enter organisms details"
            value={causesOfDistress.organisms}
            onChange={(value) => onFieldChange('causesOfDistress.organisms', value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormTextField
            rows={3}
            label="Material Properties"
            placeholder="Enter material properties details"
            value={causesOfDistress.materialProperties}
            onChange={(value) => onFieldChange('causesOfDistress.materialProperties', value)}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CausesOfDistressSection;
