import React from 'react';
import { PDFViewer, pdf } from '@react-pdf/renderer';
import { InspectionReport } from '../types';
import { InspectionReportDocument } from './components/InspectionReportDocument';
import { loadPdfDependencies, downloadPdf } from './utils/pdfUtils';

export const generateReactInspectionPDF = async (
  report: InspectionReport
): Promise<React.ReactElement> => {
  console.log('Generating PDF preview for report:', {
    id: report.id,
    photoCount: report.photos?.length || 0,
    photoUrls: report.photos?.map((p: { url: string }) => p.url) || []
  });

  try {
    const { settings, template, report: processedReport } = await loadPdfDependencies(report);

    // Create document element first
    const documentElement = (
      <InspectionReportDocument 
        report={processedReport}
        settings={settings}
        template={template}
      />
    );

    // Test PDF generation to catch any potential issues early
    await pdf(documentElement).toBlob();

    // If PDF generation succeeds, return the viewer
    return (
      <div style={{ width: '100%', height: '100vh' }}>
        <PDFViewer width="100%" height="100%">
          {documentElement}
        </PDFViewer>
      </div>
    );
  } catch (error) {
    console.error('Error generating PDF preview:', error);
    throw error;
  }
};

export const downloadReactInspectionPDF = async (
  report: InspectionReport
): Promise<void> => {
  console.log('Downloading PDF for report:', {
    id: report.id,
    photoCount: report.photos?.length || 0,
    photoUrls: report.photos?.map((p: { url: string }) => p.url) || []
  });

  try {
    const { settings, template, report: processedReport } = await loadPdfDependencies(report);
    
    const document = (
      <InspectionReportDocument 
        report={processedReport}
        settings={settings}
        template={template}
      />
    );

    const blob = await pdf(document).toBlob();
    await downloadPdf(blob, report.id);
    console.log('PDF downloaded successfully');
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};
