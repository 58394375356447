import { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { collection, addDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import type { InspectionData } from './types';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const initialFormData: InspectionData = {
  clientName: '',
  propertyAddress: '',
  inspectionDate: '',
  propertyType: '',
  photos: [],
  generalNotes: ''
};

export const useInspectionForm = (id?: string) => {
  const [formData, setFormData] = useState<InspectionData>(initialFormData);
  const [currentReportId, setCurrentReportId] = useState<string | undefined>(id);
  const [saving, setSaving] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const loadReport = async () => {
      if (!id || !currentUser) return;

      try {
        const reportDoc = await getDoc(doc(db, 'inspections', id));
        if (reportDoc.exists()) {
          const data = reportDoc.data();
          // Only load the report if it belongs to the current user
          if (data.userId === currentUser.uid) {
            setFormData(data as InspectionData);
          } else {
            navigate('/');
          }
        }
      } catch (error) {
        console.error('Error loading report:', error);
        alert('Error loading report. Please try again.');
      }
    };

    loadReport();
  }, [id, currentUser, navigate]);

  const saveProgress = async (): Promise<boolean> => {
    if (!currentUser) {
      alert('You must be logged in to save a report.');
      return false;
    }

    setSaving(true);
    try {
      if (currentReportId) {
        await updateDoc(doc(db, 'inspections', currentReportId), {
          ...formData,
          lastUpdated: new Date().toISOString(),
          status: 'draft',
          userId: currentUser.uid
        });
      } else {
        const docRef = await addDoc(collection(db, 'inspections'), {
          ...formData,
          createdAt: new Date().toISOString(),
          lastUpdated: new Date().toISOString(),
          status: 'draft',
          userId: currentUser.uid
        });
        setCurrentReportId(docRef.id);
      }
      return true;
    } catch (error) {
      console.error('Error saving progress:', error);
      alert('Error saving progress. Please try again.');
      return false;
    } finally {
      setSaving(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!currentUser) {
      alert('You must be logged in to submit a report.');
      return;
    }

    try {
      if (currentReportId) {
        await updateDoc(doc(db, 'inspections', currentReportId), {
          ...formData,
          lastUpdated: new Date().toISOString(),
          status: 'completed',
          userId: currentUser.uid
        });
      } else {
        await addDoc(collection(db, 'inspections'), {
          ...formData,
          createdAt: new Date().toISOString(),
          lastUpdated: new Date().toISOString(),
          status: 'completed',
          userId: currentUser.uid
        });
      }
      setFormData(initialFormData);
      setCurrentReportId(undefined);
      alert('Inspection report completed successfully!');
      navigate('/');
    } catch (error) {
      console.error('Error completing inspection report:', error);
      alert('Error completing inspection report. Please try again.');
    }
  };

  return {
    formData,
    setFormData,
    currentReportId,
    saving,
    saveProgress,
    handleChange,
    handleSubmit
  };
};
