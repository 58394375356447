import { useState, useEffect, useCallback } from 'react';
import { generateReactInspectionPDF } from '../../../services/pdf';
import { UsePreviewStateProps } from './types';

export const usePreviewState = ({ report, open }: UsePreviewStateProps) => {
  const [preview, setPreview] = useState<JSX.Element | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const loadPreview = useCallback(async () => {
    if (!report) return;
    
    try {
      setLoading(true);
      setError(null);
      
      console.log('Loading preview with report data:', {
        id: report.id,
        photoCount: report.photos?.length || 0,
        photos: report.photos,
        buildingSections: Object.keys(report.buildingSections || {})
      });

      const previewElement = await generateReactInspectionPDF(report);
      
      // Add a small delay to ensure UI consistency
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      console.log('Preview generated successfully');
      setPreview(previewElement);
    } catch (err) {
      console.error('Error generating preview:', err);
      setError(err instanceof Error ? err.message : 'Failed to generate preview');
    } finally {
      setLoading(false);
    }
  }, [report]);

  useEffect(() => {
    if (open) {
      void loadPreview();
    } else {
      setPreview(null);
      setLoading(false);
      setError(null);
    }
  }, [open, loadPreview]);

  const handleRetry = () => {
    setError(null);
    setLoading(true);
    void loadPreview();
  };

  return {
    preview,
    loading,
    error,
    handleRetry
  };
};
