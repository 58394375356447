export const buildingSections = [
  'foundation',
  'roof',
  'electrical',
  'plumbing',
  'hvac',
  'interior',
  'exterior'
] as const;

export const severityLevels = [
  'informational',
  'moderate',
  'critical'
] as const;

export const locations = [
  'North',
  'South',
  'East',
  'West',
  'Northeast',
  'Northwest',
  'Southeast',
  'Southwest',
  'Interior',
  'Exterior'
] as const;

export const overallConditionLevels = {
  excellent: {
    label: 'Excellent (5/5)',
    description: 'The building envelope is in exceptional condition with no significant deficiencies. Regular maintenance will preserve its current state. No immediate repairs or improvements are necessary.'
  },
  good: {
    label: 'Good (4/5)',
    description: 'The building envelope is in good condition with minor deficiencies noted. Some routine maintenance and minor repairs may be needed in the near future, but no significant issues require immediate attention.'
  },
  fair: {
    label: 'Fair (3/5)',
    description: 'The building envelope shows signs of normal wear and aging. Several components require attention within the next 1-2 years. While no critical issues were identified, proactive maintenance and repairs are recommended to prevent further deterioration.'
  },
  poor: {
    label: 'Poor (2/5)',
    description: 'The building envelope exhibits significant deterioration in multiple areas. Several components require immediate repair or replacement. Delays in addressing these issues may lead to more extensive damage and increased repair costs.'
  },
  critical: {
    label: 'Critical (1/5)',
    description: 'The building envelope is in critical condition with severe deficiencies requiring immediate attention. Multiple components have failed or are at imminent risk of failure. Immediate action is necessary to prevent potential safety hazards and avoid extensive damage.'
  }
} as const;
