import { Box, Typography } from '@mui/material';
import FormTextField from './FormTextField';

interface BuildingEnvelopeReportSectionProps {
  buildingEnvelopeReport: string;
  onFieldChange: (field: string, value: string) => void;
}

const BuildingEnvelopeReportSection = ({
  buildingEnvelopeReport,
  onFieldChange,
}: BuildingEnvelopeReportSectionProps) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Building Envelope Inspection Report
      </Typography>
      <FormTextField
        rows={4}
        label="Building Envelope Inspection Report"
        placeholder="Enter building envelope inspection report details"
        value={buildingEnvelopeReport}
        onChange={(value) => onFieldChange('buildingEnvelopeReport', value)}
      />
    </Box>
  );
};

export default BuildingEnvelopeReportSection;
