import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  CircularProgress, 
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip,
  Paper
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { downloadReactInspectionPDF, generateReactInspectionPDF } from '../services/pdf';
import { InspectionReport, Settings, Template } from '../services/types';
import { fetchSettings, fetchTemplate } from '../services/firestore';

const sampleReport: InspectionReport = {
  id: "TEST-001",
  clientName: "John Doe",
  propertyAddress: "123 Test Street",
  inspectionDate: new Date().toISOString(),
  propertyType: "Residential",
  createdAt: new Date().toISOString(),
  buildingSections: {
    exterior: {
      name: "Exterior",
      condition: 4,
      findings: "Good overall condition with minor wear",
      recommendations: "Regular maintenance recommended",
      estimatedCost: "$500",
      photos: []
    }
  },
  generalNotes: "Overall the property is in good condition.",
  photos: [
    {
      id: "photo1",
      url: "https://example.com/sample-photo.jpg",
      description: "Exterior wall condition",
      buildingSection: "exterior",
      severityLevel: "moderate",
      recommendations: "Regular maintenance and repainting recommended",
      uploadedAt: new Date().toISOString(),
      findings: "Minor wear and tear on exterior walls",
      estimatedCost: "500",
      location: "South"
    },
    {
      id: "photo2",
      url: "https://example.com/sample-photo2.jpg",
      description: "Roof damage assessment",
      buildingSection: "roof",
      severityLevel: "critical",
      recommendations: "Immediate replacement of damaged shingles required",
      uploadedAt: new Date().toISOString(),
      findings: "Significant damage to roof shingles",
      estimatedCost: "2000",
      location: "Northwest"
    }
  ]
};

const PdfPreview: React.FC<{ report?: InspectionReport }> = ({ report = sampleReport }) => {
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [preview, setPreview] = useState<JSX.Element | null>(null);
  const [settings, setSettings] = useState<Settings | null>(null);
  const [template, setTemplate] = useState<Template | null>(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const loadData = async () => {
      try {
        setDataLoading(true);
        setError(null);
        
        // Fetch required data
        const [settingsData, templateData] = await Promise.all([
          fetchSettings(),
          fetchTemplate()
        ]);

        if (!settingsData) {
          throw new Error('Failed to load company settings');
        }
        if (!templateData) {
          throw new Error('Failed to load report template');
        }

        setSettings(settingsData);
        setTemplate(templateData);
        setDataLoading(false);

        // Generate preview
        setLoading(true);
        const previewElement = await generateReactInspectionPDF(report);
        setPreview(previewElement);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load data or generate preview');
      } finally {
        setLoading(false);
        setDataLoading(false);
      }
    };

    loadData();
  }, [report]);

  const handleDownload = async () => {
    if (!settings || !template) {
      setError('Required data is missing. Please try reloading the page.');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      await downloadReactInspectionPDF(report);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to download PDF');
    } finally {
      setLoading(false);
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  if (dataLoading) {
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        minHeight={isMobile ? "40vh" : "60vh"}
      >
        <CircularProgress size={isMobile ? 40 : 48} sx={{ mb: 2 }} />
        <Typography variant={isMobile ? "body2" : "body1"}>
          Loading required data...
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        minHeight={isMobile ? "40vh" : "60vh"}
      >
        <CircularProgress size={isMobile ? 40 : 48} sx={{ mb: 2 }} />
        <Typography variant={isMobile ? "body2" : "body1"}>
          Generating PDF preview...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        color="error.main" 
        p={isMobile ? 2 : 3}
      >
        <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>
          Error
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"} align="center">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!settings || !template) {
    return (
      <Box 
        display="flex" 
        flexDirection="column" 
        justifyContent="center" 
        alignItems="center" 
        color="error.main" 
        p={isMobile ? 2 : 3}
      >
        <Typography variant={isMobile ? "subtitle1" : "h6"} gutterBottom>
          Error
        </Typography>
        <Typography variant={isMobile ? "body2" : "body1"} align="center">
          Required data is missing. Please try reloading the page.
        </Typography>
      </Box>
    );
  }

  return (
    <Paper 
      elevation={2}
      sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
    >
      <Box 
        sx={{ 
          p: isMobile ? 1 : 2,
          borderBottom: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Button
          variant="contained"
          onClick={handleDownload}
          disabled={loading || !settings || !template}
          startIcon={<DownloadIcon />}
          size={isMobile ? "small" : "medium"}
          sx={{ 
            minWidth: isMobile ? 'auto' : undefined,
            px: isMobile ? 2 : 3
          }}
        >
          {isMobile ? 'Download' : 'Download PDF'}
        </Button>

        <Tooltip title={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}>
          <IconButton 
            onClick={toggleFullscreen}
            size={isMobile ? "small" : "medium"}
          >
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      
      <Box 
        sx={{ 
          flex: 1,
          overflow: 'auto',
          bgcolor: theme.palette.grey[100],
          p: isMobile ? 1 : 2,
          '& > *': {
            touchAction: 'pan-y pinch-zoom'
          }
        }}
      >
        {preview}
      </Box>
    </Paper>
  );
};

export default PdfPreview;
