import { Box, Typography } from '@mui/material';
import FormTextField from './FormTextField';

interface GeneralConditionsSectionProps {
  generalConditionsSection: string;
  onFieldChange: (field: string, value: string) => void;
}

const GeneralConditionsSection = ({
  generalConditionsSection,
  onFieldChange,
}: GeneralConditionsSectionProps) => {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        General Conditions
      </Typography>
      <FormTextField
        rows={4}
        label="General Conditions"
        placeholder="Enter general conditions details"
        value={generalConditionsSection}
        onChange={(value) => onFieldChange('generalConditionsSection', value)}
      />
    </Box>
  );
};

export default GeneralConditionsSection;
