import { StyleSheet } from '@react-pdf/renderer';

// Constants for consistent spacing
const FOOTER_HEIGHT = 60;
const PAGE_MARGIN = 30;
const SECTION_MARGIN = 15;
const CONTENT_PADDING = 8;

// Color palette
const COLORS = {
  primary: '#1976D2',
  primaryLight: '#E3F2FD',
  critical: '#D32F2F',
  criticalBg: '#FFEBEE',
  moderate: '#F57C00',
  moderateBg: '#FFF3E0',
  informational: '#0288D1',
  informationalBg: '#E1F5FE',
  text: '#424242',
  textLight: '#616161',
  border: '#E0E0E0',
  background: '#FFFFFF',
  backgroundGrey: '#F5F5F5',
};

export const styles = StyleSheet.create({
  page: {
    paddingTop: PAGE_MARGIN,
    paddingBottom: PAGE_MARGIN + FOOTER_HEIGHT,
    paddingHorizontal: PAGE_MARGIN,
    fontSize: 12,
    fontFamily: 'Times-Roman',
    position: 'relative',
    backgroundColor: COLORS.background,
  },
  contentPage: {
    flex: 1,
    flexDirection: 'column',
    padding: PAGE_MARGIN,
  },
  section: {
    marginVertical: SECTION_MARGIN,
    width: '100%',
  },
  header: {
    backgroundColor: COLORS.primary,
    padding: 15,
    marginBottom: 20,
    borderRadius: 4,
  },
  title: {
    fontSize: 24,
    color: COLORS.background,
    fontFamily: 'Times-Bold',
  },
  sectionTitle: {
    fontSize: 24,
    fontFamily: 'Times-Bold',
    marginBottom: SECTION_MARGIN,
    color: COLORS.primary,
    borderBottom: `2pt solid ${COLORS.primary}`,
    paddingBottom: 5,
  },
  subTitle: {
    fontSize: 16,
    fontFamily: 'Times-Bold',
    marginBottom: 8,
    color: COLORS.primary,
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
    lineHeight: 1.5,
    color: COLORS.text,
  },
  contentBlock: {
    marginBottom: 15,
    padding: CONTENT_PADDING,
    backgroundColor: COLORS.backgroundGrey,
    borderRadius: 4,
    width: '100%',
  },

  // Condition styles
  conditionContainer: {
    backgroundColor: COLORS.primaryLight,
    padding: 12,
    marginBottom: 15,
    borderRadius: 4,
    borderLeft: `4pt solid ${COLORS.primary}`,
  },
  conditionValue: {
    fontSize: 16,
    fontFamily: 'Times-Bold',
    color: COLORS.primary,
  },
  conditionHeader: {
    fontSize: 14,
    fontFamily: 'Times-Bold',
    marginBottom: CONTENT_PADDING,
    color: COLORS.primary,
    backgroundColor: COLORS.primaryLight,
    padding: CONTENT_PADDING,
    borderRadius: 4,
    width: '100%',
  },

  // Table styles
  tableHeaderRow: {
    flexDirection: 'row',
    backgroundColor: COLORS.primary,
    padding: 10,
    borderRadius: 4,
    marginBottom: 2,
  },
  tableHeaderCell: {
    color: COLORS.background,
    fontSize: 12,
    fontFamily: 'Times-Bold',
    padding: 8,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: COLORS.border,
    borderBottomStyle: 'solid',
    minHeight: 40,
    padding: 8,
  },
  tableCell: {
    fontSize: 12,
    padding: 8,
    color: COLORS.text,
  },

  // Photo section specific styles
  photoSection: {
    marginBottom: SECTION_MARGIN * 2,
    width: '100%',
  },
  photoSectionTitle: {
    fontSize: 20,
    fontFamily: 'Times-Bold',
    marginBottom: 15,
    color: COLORS.primary,
    backgroundColor: COLORS.primaryLight,
    padding: 10,
    borderRadius: 4,
  },
  photoContainer: {
    marginBottom: 20,
    width: '100%',
  },
  photo: {
    width: '100%',
    height: 300,
    objectFit: 'contain',
    marginBottom: 10,
    borderRadius: 4,
  },
  photoDetailsBlock: {
    backgroundColor: COLORS.backgroundGrey,
    padding: 15,
    width: '100%',
    borderRadius: 4,
    border: `1pt solid ${COLORS.border}`,
  },
  photoDetailRow: {
    flexDirection: 'row',
    marginBottom: 8,
    width: '100%',
  },
  photoDetailLabel: {
    fontSize: 12,
    fontFamily: 'Times-Bold',
    width: 120,
    color: COLORS.primary,
  },
  photoDetailText: {
    fontSize: 12,
    flex: 1,
    color: COLORS.text,
  },

  // Severity styles
  severityBox: {
    padding: CONTENT_PADDING * 2,
    margin: 5,
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 4,
  },
  severityBoxCritical: {
    backgroundColor: COLORS.criticalBg,
    borderColor: COLORS.critical,
  },
  severityBoxModerate: {
    backgroundColor: COLORS.moderateBg,
    borderColor: COLORS.moderate,
  },
  severityBoxInformational: {
    backgroundColor: COLORS.informationalBg,
    borderColor: COLORS.informational,
  },
  severityLabel: {
    fontSize: 12,
    textAlign: 'center',
  },
  severityCritical: {
    color: COLORS.critical,
  },
  severityModerate: {
    color: COLORS.moderate,
  },
  severityInformational: {
    color: COLORS.informational,
  },

  // Cost styles
  costSummaryContainer: {
    marginTop: 15,
    padding: CONTENT_PADDING * 2,
    width: '100%',
    backgroundColor: COLORS.backgroundGrey,
    borderRadius: 4,
  },
  costRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
    paddingBottom: 8,
    borderBottom: `0.5pt solid ${COLORS.border}`,
    width: '100%',
  },
  costLabel: {
    flex: 2,
    fontFamily: 'Times-Bold',
    color: COLORS.text,
  },
  costValue: {
    flex: 1,
    textAlign: 'right',
    color: COLORS.text,
  },
  totalCostRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 15,
    padding: 10,
    backgroundColor: COLORS.primaryLight,
    borderRadius: 4,
  },
  totalLabel: {
    fontSize: 14,
    fontFamily: 'Times-Bold',
    color: COLORS.primary,
    textAlign: 'right',
    paddingRight: 15,
  },
  totalValue: {
    fontSize: 14,
    fontFamily: 'Times-Bold',
    color: COLORS.primary,
  },

  // Footer styles
  footer: {
    position: 'absolute',
    bottom: PAGE_MARGIN,
    left: PAGE_MARGIN,
    right: PAGE_MARGIN,
    borderTop: `1pt solid ${COLORS.border}`,
    paddingTop: CONTENT_PADDING,
  },
  footerText: {
    fontSize: 10,
    color: COLORS.textLight,
    textAlign: 'center',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    color: COLORS.textLight,
    right: 0,
    bottom: 0,
  },

  // Misc styles
  noPhotosText: {
    fontSize: 12,
    fontStyle: 'italic',
    color: COLORS.textLight,
    textAlign: 'center',
    marginTop: 20,
    width: '100%',
  },
});
