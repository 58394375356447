import { 
  Card, 
  CardContent, 
  CardMedia, 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Grid,
  useTheme,
  useMediaQuery,
  Button,
  Box
} from '@mui/material';
import { useState, useEffect } from 'react';
import { buildingSections, severityLevels, locations } from '../constants';
import type { PhotoDetails } from '../types';

interface PhotoCardProps {
  photo: PhotoDetails;
  onUpdate: (updatedPhoto: PhotoDetails) => void;
  onHasUnsavedChanges: (hasChanges: boolean) => void;
}

const PhotoCard = ({ photo, onUpdate, onHasUnsavedChanges }: PhotoCardProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [localPhoto, setLocalPhoto] = useState<PhotoDetails>(photo);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  useEffect(() => {
    setLocalPhoto(photo);
  }, [photo]);

  useEffect(() => {
    const hasChanges = JSON.stringify(localPhoto) !== JSON.stringify(photo);
    setHasUnsavedChanges(hasChanges);
    onHasUnsavedChanges(hasChanges);
  }, [localPhoto, photo, onHasUnsavedChanges]);

  const handleChange = (field: keyof PhotoDetails, value: string | number) => {
    setLocalPhoto(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSave = () => {
    onUpdate(localPhoto);
    setHasUnsavedChanges(false);
  };

  // Helper function to capitalize first letter
  const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

  return (
    <Card 
      sx={{ 
        mb: 2,
        '& .MuiFormControl-root': {
          mb: isMobile ? 1.5 : 2
        },
        '& .MuiInputLabel-root': {
          fontSize: isMobile ? '0.875rem' : undefined
        },
        '& .MuiSelect-select, & .MuiInputBase-input': {
          fontSize: isMobile ? '0.875rem' : undefined,
          py: isMobile ? 1 : undefined
        }
      }}
    >
      <CardMedia
        component="img"
        height={isMobile ? "200" : "300"}
        image={photo.url}
        alt="Inspection photo"
        sx={{ 
          objectFit: 'contain',
          bgcolor: 'black'
        }}
      />
      <CardContent sx={{ p: isMobile ? 2 : 3 }}>
        <Grid container spacing={isMobile ? 1.5 : 2}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth size={isMobile ? "small" : "medium"}>
              <InputLabel>Building Section</InputLabel>
              <Select
                value={localPhoto.buildingSection}
                label="Building Section"
                onChange={(e) => handleChange('buildingSection', e.target.value)}
              >
                {buildingSections.map((section) => (
                  <MenuItem key={section} value={section}>
                    {capitalize(section)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth size={isMobile ? "small" : "medium"}>
              <InputLabel>Location</InputLabel>
              <Select
                value={localPhoto.location || ''}
                label="Location"
                onChange={(e) => handleChange('location', e.target.value)}
              >
                {locations.map((location) => (
                  <MenuItem key={location} value={location}>
                    {location}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth size={isMobile ? "small" : "medium"}>
              <InputLabel>Severity Level</InputLabel>
              <Select
                value={localPhoto.severityLevel}
                label="Severity Level"
                onChange={(e) => handleChange('severityLevel', e.target.value)}
              >
                {severityLevels.map((level) => (
                  <MenuItem key={level} value={level}>
                    {capitalize(level)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={isMobile ? 3 : 2}
              label="Findings"
              value={localPhoto.findings}
              onChange={(e) => handleChange('findings', e.target.value)}
              size={isMobile ? "small" : "medium"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={isMobile ? 3 : 2}
              label="Recommendations"
              value={localPhoto.recommendations}
              onChange={(e) => handleChange('recommendations', e.target.value)}
              size={isMobile ? "small" : "medium"}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Estimated Cost"
              value={localPhoto.estimatedCost}
              onChange={(e) => handleChange('estimatedCost', e.target.value)}
              size={isMobile ? "small" : "medium"}
              inputProps={{
                min: "0",
                step: "1",
                pattern: "[0-9]*"
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={!hasUnsavedChanges}
                sx={{ minWidth: 100 }}
              >
                {hasUnsavedChanges ? 'Save*' : 'Save'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PhotoCard;
