import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  User,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  sendEmailVerification,
  updateProfile
} from 'firebase/auth';
import { auth } from '../firebase/config';
import { ensureUserDocument } from '../services/firestore';

interface AuthContextType {
  currentUser: User | null;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string, displayName: string) => Promise<void>;
  logout: () => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updateUserProfile: (displayName: string) => Promise<void>;
  resendVerificationEmail: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Rate limiting configuration
const RATE_LIMIT_ATTEMPTS = 5;
const RATE_LIMIT_DURATION = 15 * 60 * 1000; // 15 minutes

interface RateLimitState {
  attempts: number;
  lastAttempt: number;
  blockedUntil: number;
}

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [rateLimit, setRateLimit] = useState<RateLimitState>({
    attempts: 0,
    lastAttempt: 0,
    blockedUntil: 0
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed. User:', user?.email, 'Verified:', user?.emailVerified);
      
      if (user) {
        try {
          // Always attempt to ensure user document exists on auth state change
          const result = await ensureUserDocument(user.uid, user.email || '', user.displayName || '');
          if (!result) {
            console.error('Failed to ensure user document exists');
            // Don't set the user if we couldn't create their document
            setCurrentUser(null);
            setLoading(false);
            return;
          }
        } catch (error: any) {
          console.error('Error ensuring user document on auth state change:', error);
          // Don't set the user if we couldn't create their document
          setCurrentUser(null);
          setLoading(false);
          return;
        }
      }
      
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const checkRateLimit = () => {
    const now = Date.now();
    
    if (now < rateLimit.blockedUntil) {
      const minutesLeft = Math.ceil((rateLimit.blockedUntil - now) / 60000);
      throw new Error(`Too many attempts. Please try again in ${minutesLeft} minutes.`);
    }

    if (now - rateLimit.lastAttempt > RATE_LIMIT_DURATION) {
      // Reset rate limit if duration has passed
      setRateLimit({
        attempts: 1,
        lastAttempt: now,
        blockedUntil: 0
      });
    } else {
      // Increment attempts
      const newAttempts = rateLimit.attempts + 1;
      setRateLimit({
        attempts: newAttempts,
        lastAttempt: now,
        blockedUntil: newAttempts >= RATE_LIMIT_ATTEMPTS ? now + RATE_LIMIT_DURATION : 0
      });

      if (newAttempts >= RATE_LIMIT_ATTEMPTS) {
        throw new Error('Too many attempts. Please try again in 15 minutes.');
      }
    }
  };

  const signup = async (email: string, password: string, displayName: string) => {
    checkRateLimit();
    const { user } = await createUserWithEmailAndPassword(auth, email, password);
    await updateProfile(user, { displayName });
    
    try {
      const result = await ensureUserDocument(user.uid, email, displayName);
      if (!result) {
        // If we can't create the user document, delete the auth user
        await user.delete();
        throw new Error('Failed to complete signup process. Please try again.');
      }
    } catch (error: any) {
      // If we can't create the user document, delete the auth user
      await user.delete();
      throw new Error('Failed to complete signup process. Please try again.');
    }
    
    await sendEmailVerification(user);
  };

  const login = async (email: string, password: string) => {
    checkRateLimit();
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    
    if (!user.emailVerified) {
      throw new Error('Please verify your email before logging in.');
    }

    try {
      const result = await ensureUserDocument(user.uid, email, user.displayName || '');
      if (!result) {
        throw new Error('Failed to create/update user document');
      }
    } catch (error: any) {
      // If we can't create/update the user document, prevent login
      await signOut(auth);
      throw new Error('Failed to complete login process. Please try again.');
    }
  };

  const logout = async () => {
    await signOut(auth);
  };

  const resetPassword = async (email: string) => {
    checkRateLimit();
    await sendPasswordResetEmail(auth, email);
  };

  const updateUserProfile = async (displayName: string) => {
    if (!currentUser) throw new Error('No user logged in');
    await updateProfile(currentUser, { displayName });
    
    try {
      const result = await ensureUserDocument(currentUser.uid, currentUser.email || '', displayName);
      if (!result) {
        throw new Error('Failed to update profile');
      }
    } catch (error: any) {
      console.error('Error updating user document:', error);
      throw new Error('Failed to update profile. Please try again.');
    }
  };

  const resendVerificationEmail = async () => {
    if (!currentUser) throw new Error('No user logged in');
    checkRateLimit();
    await sendEmailVerification(currentUser);
  };

  const value = {
    currentUser,
    loading,
    login,
    signup,
    logout,
    resetPassword,
    updateUserProfile,
    resendVerificationEmail
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
