import { useState } from 'react';
import { doc, deleteDoc, writeBatch } from 'firebase/firestore';
import { ref, deleteObject } from 'firebase/storage';
import { db, auth, storage } from '../../../firebase/config';
import { InspectionReport } from '../../../services/types';

export const useReportActions = (
  reports: InspectionReport[],
  setReports: (reports: InspectionReport[]) => void,
  refetch?: () => Promise<void> // Add refetch parameter
) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedReport, setSelectedReport] = useState<InspectionReport | null>(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [reportToDelete, setReportToDelete] = useState<string | null>(null);

  const handlePreviewReport = (report: InspectionReport) => {
    setSelectedReport(report);
    setPreviewOpen(true);
  };

  const handleGeneratePDF = async (report: InspectionReport) => {
    // PDF generation logic here
    console.log('Generating PDF for report:', report.id);
  };

  const handleDeleteClick = (reportId: string) => {
    setReportToDelete(reportId);
    setDeleteDialogOpen(true);
  };

  const deletePhotosFromStorage = async (photos: { url: string }[]) => {
    const deletePromises = photos.map(async (photo) => {
      try {
        // Extract the file path from the URL
        const photoUrl = new URL(photo.url);
        const storagePath = decodeURIComponent(photoUrl.pathname.split('/o/')[1].split('?')[0]);
        const photoRef = ref(storage, storagePath);
        
        await deleteObject(photoRef);
      } catch (error) {
        console.error('Error deleting photo:', error);
        // Continue with other deletions even if one fails
      }
    });

    await Promise.all(deletePromises);
  };

  const handleDeleteConfirm = async () => {
    if (!reportToDelete) return;

    setIsDeleting(true);
    setError(null);

    try {
      if (!auth.currentUser) {
        throw new Error('Authentication required to delete report');
      }

      // Get the report to be deleted
      const reportToBeDeleted = reports.find(report => report.id === reportToDelete);
      if (!reportToBeDeleted) {
        throw new Error('Report not found');
      }

      // Start a batch write
      const batch = writeBatch(db);

      // Delete the main report document from the 'inspections' collection
      const reportRef = doc(db, 'inspections', reportToDelete);
      batch.delete(reportRef);

      // Delete any related documents (e.g., sections, notes, etc.)
      if (reportToBeDeleted.buildingSections) {
        Object.entries(reportToBeDeleted.buildingSections).forEach(([sectionId, section]) => {
          if (section) {
            const sectionRef = doc(db, `inspections/${reportToDelete}/sections`, sectionId);
            batch.delete(sectionRef);
          }
        });
      }

      // Commit the batch
      await batch.commit();

      // Delete photos from storage if they exist
      if (reportToBeDeleted.photos && reportToBeDeleted.photos.length > 0) {
        await deletePhotosFromStorage(reportToBeDeleted.photos);
      }

      // Update local state
      setReports(reports.filter(report => report.id !== reportToDelete));
      
      // Close the dialog
      setDeleteDialogOpen(false);
      setReportToDelete(null);

      // Refetch the reports to ensure sync with server
      if (refetch) {
        await refetch();
      }

      console.log('Report and associated data successfully deleted');
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to delete report';
      setError(errorMessage);
      console.error('Error deleting report:', error);
      
      // Re-throw the error to ensure the UI knows about the failure
      throw error;
    } finally {
      setIsDeleting(false);
    }
  };

  return {
    isDeleting,
    error,
    selectedReport,
    previewOpen,
    deleteDialogOpen,
    setPreviewOpen,
    setDeleteDialogOpen,
    setReportToDelete,
    handlePreviewReport,
    handleGeneratePDF,
    handleDeleteClick,
    handleDeleteConfirm
  };
};
