import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { InspectionReport } from '../../../types';
import { styles } from '../../styles/pdfStyles';
import { SeverityLevel } from '../../../../components/inspection-form/types';
import { formatCurrency } from '../../../../utils/numberFormat';

interface IssuesListSectionProps {
  report: InspectionReport;
}

export const IssuesListSection: React.FC<IssuesListSectionProps> = ({ report }) => {
  // Extract issues from photos and normalize severity levels
  const issues = report.photos?.map(photo => ({
    severityLevel: normalizeSeverityLevel(photo.severityLevel),
    location: photo.location,
    recommendations: photo.recommendations,
    estimatedCost: photo.estimatedCost,
  })) || [];

  // Function to normalize legacy severity levels
  function normalizeSeverityLevel(severity: string): SeverityLevel {
    switch (severity?.toLowerCase()) {
      case 'critical':
        return 'critical';
      case 'moderate':
        return 'moderate';
      case 'low': // Convert legacy 'low' to 'informational'
      case 'informational':
      default:
        return 'informational';
    }
  }

  const getSeverityStyle = (severity: SeverityLevel) => {
    switch (severity) {
      case 'critical':
        return {
          color: '#D32F2F',
          fontFamily: 'Times-Bold',
          backgroundColor: '#FFEBEE',
          padding: 4,
          borderRadius: 4,
        };
      case 'moderate':
        return {
          color: '#F57C00',
          fontFamily: 'Times-Bold',
          backgroundColor: '#FFF3E0',
          padding: 4,
          borderRadius: 4,
        };
      case 'informational':
      default:
        return {
          color: '#0288D1',
          fontFamily: 'Times-Bold',
          backgroundColor: '#E1F5FE',
          padding: 4,
          borderRadius: 4,
        };
    }
  };

  // Calculate total cost
  const totalCost = issues.reduce((sum, issue) => {
    const cost = parseFloat(issue.estimatedCost || '0');
    return isNaN(cost) ? sum : sum + cost;
  }, 0);

  return (
    <View style={styles.section}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>Identified Issues</Text>
      </View>
      
      {/* Table Header */}
      <View style={[styles.tableHeaderRow, { marginTop: 15 }]}>
        <Text style={[styles.tableHeaderCell, { flex: 1 }]}>Severity Level</Text>
        <Text style={[styles.tableHeaderCell, { flex: 1.5 }]}>Location</Text>
        <Text style={[styles.tableHeaderCell, { flex: 2 }]}>Recommendation</Text>
        <Text style={[styles.tableHeaderCell, { flex: 1 }]}>Estimated Cost</Text>
      </View>

      {/* Table Content */}
      {issues.map((issue, index) => (
        <View key={index} style={[
          styles.tableRow,
          { backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F5F5F5' }
        ]}>
          <View style={[styles.tableCell, { flex: 1 }]}>
            <Text style={getSeverityStyle(issue.severityLevel)}>
              {issue.severityLevel.charAt(0).toUpperCase() + issue.severityLevel.slice(1)}
            </Text>
          </View>
          <Text style={[styles.tableCell, { flex: 1.5 }]}>{issue.location || 'N/A'}</Text>
          <Text style={[styles.tableCell, { flex: 2 }]}>{issue.recommendations || 'N/A'}</Text>
          <Text style={[styles.tableCell, { flex: 1 }]}>
            {issue.estimatedCost ? `$${formatCurrency(issue.estimatedCost)}` : 'N/A'}
          </Text>
        </View>
      ))}

      {issues.length === 0 ? (
        <Text style={styles.noPhotosText}>No issues identified.</Text>
      ) : (
        <View style={styles.totalCostRow}>
          <Text style={[styles.tableCell, { flex: 4.5 }]}></Text>
          <Text style={[styles.totalLabel, { flex: 2 }]}>Total Estimated Cost:</Text>
          <Text style={[styles.totalValue, { flex: 1 }]}>${formatCurrency(totalCost)}</Text>
        </View>
      )}
    </View>
  );
};
