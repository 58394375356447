import { useEffect, useState, useCallback } from 'react';
import { collection, query, orderBy, getDocs, where } from 'firebase/firestore';
import { db } from '../../../firebase/config';
import { InspectionReport } from '../../../services/types';
import { ReportsState } from '../types';
import { useAuth } from '../../../contexts/AuthContext';

const initialState: ReportsState = {
  reports: [],
  loading: true,
  error: null
};

export const useReportsList = () => {
  const [state, setState] = useState<ReportsState>(initialState);
  const { currentUser } = useAuth();

  const setReports = useCallback((reports: InspectionReport[]) => {
    setState(prev => ({ ...prev, reports }));
  }, []);

  const fetchReports = useCallback(async () => {
    if (!currentUser) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: 'User must be authenticated to fetch reports'
      }));
      return;
    }

    try {
      setState(prev => ({ ...prev, loading: true, error: null }));

      const reportsQuery = query(
        collection(db, 'inspections'),
        where('userId', '==', currentUser.uid),
        orderBy('createdAt', 'desc')
      );
      
      const querySnapshot = await getDocs(reportsQuery);
      const reportsData = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          clientName: data.clientName || '',
          propertyAddress: data.propertyAddress || '',
          inspectionDate: data.inspectionDate || new Date().toISOString(),
          propertyType: data.propertyType || '',
          createdAt: data.createdAt || new Date().toISOString(),
          buildingSections: data.buildingSections || {},
          generalNotes: data.generalNotes || '',
          photos: data.photos || [],
          userId: data.userId || currentUser.uid
        } as InspectionReport;
      });
      
      setState(prev => ({
        ...prev,
        reports: reportsData,
        loading: false,
        error: null
      }));
    } catch (error) {
      console.error('Error fetching reports:', error);
      setState(prev => ({
        ...prev,
        loading: false,
        error: error instanceof Error ? error.message : 'Failed to fetch reports'
      }));
    }
  }, [currentUser]);

  useEffect(() => {
    void fetchReports();
  }, [fetchReports]);

  return {
    reports: state.reports,
    loading: state.loading,
    error: state.error,
    setReports,
    refetch: fetchReports
  };
};
