import { FC, useEffect, useState, useCallback } from 'react';
import { collection, query, getDocs, orderBy, deleteDoc, doc, getDoc, limit, startAfter } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { LoadingSpinner } from '../common/LoadingSpinner';
import ProposalsLayout from './layout/ProposalsLayout';
import ProposalsHeader from './header/ProposalsHeader';
import ProposalsTable from './ProposalsTable';
import { formatDate } from '../reports/utils/dateUtils';
import { Proposal } from './types';
import { generateProposalPdfBlob, downloadProposalPdf } from '../../services/pdf/utils/proposalPdfUtils';
import { Dialog, DialogContent, Button, Box } from '@mui/material';
import { fetchSettings } from '../../services/firestore';
import { Settings } from '../../services/types';
import { useLocation } from 'react-router-dom';
import ProposalPreview from './ProposalPreview';

const PROPOSALS_PER_PAGE = 10;

const ProposalsList: FC = () => {
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [previewProposal, setPreviewProposal] = useState<Proposal | null>(null);
  const [settings, setSettings] = useState<Settings | null>(null);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [hasMore, setHasMore] = useState(true);
  const location = useLocation();

  const fetchProposals = async (lastDoc?: any) => {
    const proposalsRef = collection(db, 'proposals');
    let q = query(
      proposalsRef,
      orderBy('createdAt', 'desc'),
      limit(PROPOSALS_PER_PAGE)
    );

    if (lastDoc) {
      q = query(
        proposalsRef,
        orderBy('createdAt', 'desc'),
        startAfter(lastDoc),
        limit(PROPOSALS_PER_PAGE)
      );
    }

    const querySnapshot = await getDocs(q);
    
    // Set last visible document for pagination
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    setLastVisible(lastVisible);
    
    // Check if there are more results
    setHasMore(querySnapshot.docs.length === PROPOSALS_PER_PAGE);
    
    return querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    })) as Proposal[];
  };

  const loadMoreProposals = async () => {
    if (!hasMore || loadingMore) return;
    
    try {
      setLoadingMore(true);
      const moreProposals = await fetchProposals(lastVisible);
      setProposals(prev => [...prev, ...moreProposals]);
    } catch (error) {
      console.error('Error loading more proposals:', error);
      setError('Error loading more proposals');
    } finally {
      setLoadingMore(false);
    }
  };

  const refreshData = useCallback(async () => {
    try {
      setLoading(true);
      const [proposalsData, settingsData] = await Promise.all([
        fetchProposals(),
        fetchSettings()
      ]);
      
      setProposals(proposalsData);
      setSettings(settingsData);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Error loading data');
    } finally {
      setLoading(false);
    }
  }, []); // No dependencies needed as all used functions/values are stable

  useEffect(() => {
    refreshData();
  }, [refreshData, location]);

  const handlePreview = async (proposal: Proposal) => {
    try {
      // Get the full proposal document to include selected states
      const proposalDoc = await getDoc(doc(db, 'proposals', proposal.id));
      const fullProposal = { ...proposalDoc.data(), id: proposalDoc.id } as Proposal & {
        levelOneSelected?: boolean;
        levelTwoSelected?: boolean;
        levelThreeSelected?: boolean;
        levelFourSelected?: boolean;
        threeYear133Selected?: boolean;
        threeYear233Selected?: boolean;
      };
      setPreviewProposal(fullProposal);
    } catch (error) {
      console.error('Error loading proposal for preview:', error);
      alert('Error loading proposal preview');
    }
  };

  const handleGeneratePDF = async (proposal: Proposal) => {
    try {
      // Get the full proposal document to include selected states
      const proposalDoc = await getDoc(doc(db, 'proposals', proposal.id));
      const fullProposal = { ...proposalDoc.data(), id: proposalDoc.id } as Proposal & {
        levelOneSelected?: boolean;
        levelTwoSelected?: boolean;
        levelThreeSelected?: boolean;
        levelFourSelected?: boolean;
        threeYear133Selected?: boolean;
        threeYear233Selected?: boolean;
      };

      const blob = await generateProposalPdfBlob(fullProposal, settings?.company.proposalBase);
      await downloadProposalPdf(blob, proposal.id);
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Error generating PDF');
    }
  };

  const handleDelete = async (proposal: Proposal) => {
    if (window.confirm('Are you sure you want to delete this proposal?')) {
      try {
        await deleteDoc(doc(db, 'proposals', proposal.id));
        setProposals(proposals.filter(p => p.id !== proposal.id));
      } catch (error) {
        console.error('Error deleting proposal:', error);
        alert('Error deleting proposal');
      }
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div>Error loading proposals</div>;
  }

  return (
    <ProposalsLayout>
      <ProposalsHeader />
      <ProposalsTable
        proposals={proposals}
        onPreview={handlePreview}
        onGeneratePDF={handleGeneratePDF}
        onDelete={handleDelete}
        formatDate={formatDate}
      />
      
      {hasMore && (
        <Box display="flex" justifyContent="center" my={2}>
          <Button 
            variant="contained" 
            onClick={loadMoreProposals}
            disabled={loadingMore}
          >
            {loadingMore ? 'Loading...' : 'Load More'}
          </Button>
        </Box>
      )}

      <Dialog
        open={!!previewProposal}
        onClose={() => setPreviewProposal(null)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent style={{ height: '90vh', padding: 0 }}>
          {previewProposal && (
            <ProposalPreview 
              proposal={previewProposal}
              backgroundImages={settings?.company.proposalBase}
            />
          )}
        </DialogContent>
      </Dialog>
    </ProposalsLayout>
  );
};

export default ProposalsList;
