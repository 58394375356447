import { db, auth } from '../firebase/config';
import { doc, getDoc, setDoc, collection } from 'firebase/firestore';
import { Settings, Template, InspectionReport, BrandingAssets } from './types';
import { ProposalFormData, ProposalDocument } from '../components/proposals/types';

// Use a fixed document ID for shared settings
const SHARED_SETTINGS_ID = 'shared_settings';

// Default empty settings structure
const emptySettings: Settings = {
  company: {
    companyName: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    website: '',
    phone: '',
    email: '',
    basicLogo: null,
    coverBackground: null,
    proposalBase: [],
    invoiceBase: null
  },
  personal: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    title: ''
  },
  preferences: {
    autoSaveReports: false,
    includeCompanyLogo: false,
    includeCoverBackground: false,
    darkMode: false,
    compactView: false
  },
  prices: {}
};

export const ensureUserDocument = async (userId: string, email: string, displayName?: string): Promise<boolean> => {
  if (!userId || !email) {
    console.error('Cannot create user document: Missing required fields', { userId, email });
    return false;
  }

  try {
    console.log('Ensuring user document exists:', { userId, email });
    const userRef = doc(db, 'users', userId);
    const userSnap = await getDoc(userRef);

    const userData = {
      email,
      displayName: displayName || '',
      lastLogin: new Date().toISOString(),
    };

    if (!userSnap.exists()) {
      console.log('Creating new user document:', userId);
      await setDoc(userRef, {
        ...userData,
        createdAt: new Date().toISOString(),
        role: 'user'
      });
      console.log('User document created successfully:', userId);
      return true;
    } else {
      console.log('Updating existing user document:', userId);
      await setDoc(userRef, userData, { merge: true });
      console.log('User document updated successfully:', userId);
      return true;
    }
  } catch (error) {
    console.error('Error in ensureUserDocument:', error);
    // Re-throw the error to be handled by the calling function
    throw error;
  }
};

// Deprecated - use ensureUserDocument instead
export const createUserDocument = async (userId: string, email: string, displayName?: string) => {
  console.warn('createUserDocument is deprecated. Use ensureUserDocument instead.');
  return ensureUserDocument(userId, email, displayName);
};

// Helper function to ensure settings document exists
const ensureSettingsDocument = async (): Promise<Settings> => {
  const settingsRef = doc(db, 'settings', SHARED_SETTINGS_ID);
  const settingsSnap = await getDoc(settingsRef);
  
  if (!settingsSnap.exists()) {
    console.log('Creating new settings document');
    const initialSettings = {
      ...emptySettings,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      createdBy: auth.currentUser?.uid
    };
    await setDoc(settingsRef, initialSettings);
    return initialSettings;
  }
  
  return settingsSnap.data() as Settings;
};

export const fetchSettings = async (): Promise<Settings | null> => {
  try {
    if (!auth.currentUser) {
      throw new Error('Authentication required to access settings');
    }

    // This will ensure the settings document exists and return its contents
    const settings = await ensureSettingsDocument();
    return settings;
  } catch (error) {
    console.error('Error fetching settings:', error);
    throw error;
  }
};

export const saveSettings = async (settings: Partial<Settings>): Promise<void> => {
  try {
    if (!auth.currentUser) {
      throw new Error('Authentication required to save settings');
    }

    const settingsRef = doc(db, 'settings', SHARED_SETTINGS_ID);
    
    // First get the current settings
    const currentSettings = await ensureSettingsDocument();
    
    // Deep merge the new settings with current settings
    const mergedSettings = {
      ...currentSettings,
      ...settings,
      // Ensure prices are properly merged if they exist in new settings
      ...(settings.prices && {
        prices: {
          ...currentSettings.prices,
          ...settings.prices
        }
      }),
      // Add metadata
      updatedAt: new Date().toISOString(),
      lastUpdatedBy: auth.currentUser.uid
    };

    // Save the merged settings
    await setDoc(settingsRef, mergedSettings);
  } catch (error) {
    console.error('Error saving settings:', error);
    throw error;
  }
};

export const fetchBrandingAssets = async (): Promise<BrandingAssets | null> => {
  try {
    if (!auth.currentUser) {
      throw new Error('Authentication required to access branding');
    }

    const settings = await ensureSettingsDocument();
    return settings.company || null;
  } catch (error) {
    console.error('Error fetching branding:', error);
    throw error;
  }
};

export const updateBrandingAssets = async (branding: BrandingAssets): Promise<void> => {
  try {
    if (!auth.currentUser) {
      throw new Error('Authentication required to update branding');
    }

    const settingsRef = doc(db, 'settings', SHARED_SETTINGS_ID);
    
    // First get current settings to ensure proper merge
    const currentSettings = await ensureSettingsDocument();
    
    await setDoc(settingsRef, {
      ...currentSettings,
      company: {
        ...currentSettings.company,
        ...branding
      },
      updatedAt: new Date().toISOString(),
      lastUpdatedBy: auth.currentUser.uid
    });
  } catch (error) {
    console.error('Error updating branding:', error);
    throw error;
  }
};

export const fetchTemplate = async (): Promise<Template | null> => {
  try {
    if (!auth.currentUser) {
      throw new Error('Authentication required to access template');
    }

    const templateRef = doc(db, 'templates', 'introTemplate');
    const templateSnap = await getDoc(templateRef);
    
    if (templateSnap.exists()) {
      return templateSnap.data() as Template;
    }
    return null;
  } catch (error) {
    console.error('Error fetching template:', error);
    throw error;
  }
};

export const fetchReport = async (reportId: string): Promise<InspectionReport | null> => {
  try {
    if (!auth.currentUser) {
      throw new Error('Authentication required to access report');
    }

    const reportRef = doc(db, 'reports', reportId);
    const reportSnap = await getDoc(reportRef);
    
    if (reportSnap.exists()) {
      return reportSnap.data() as InspectionReport;
    }
    return null;
  } catch (error) {
    console.error('Error fetching report:', error);
    throw error;
  }
};

export const createProposal = async (proposalData: ProposalFormData): Promise<string> => {
  try {
    if (!auth.currentUser) {
      throw new Error('Authentication required to create proposal');
    }

    const proposalsRef = collection(db, 'proposals');
    const newProposalRef = doc(proposalsRef);
    
    const proposalDoc: ProposalDocument = {
      ...proposalData,
      id: newProposalRef.id,
      createdAt: new Date(),
      userId: auth.currentUser.uid
    };

    await setDoc(newProposalRef, proposalDoc);
    return newProposalRef.id;
  } catch (error) {
    console.error('Error creating proposal:', error);
    throw error;
  }
};

export const fetchProposal = async (proposalId: string): Promise<ProposalDocument | null> => {
  try {
    if (!auth.currentUser) {
      throw new Error('Authentication required to fetch proposal');
    }

    const proposalRef = doc(db, 'proposals', proposalId);
    const proposalSnap = await getDoc(proposalRef);
    
    if (proposalSnap.exists()) {
      return proposalSnap.data() as ProposalDocument;
    }
    return null;
  } catch (error) {
    console.error('Error fetching proposal:', error);
    throw error;
  }
};

export const updateProposal = async (proposalId: string, proposalData: ProposalFormData): Promise<void> => {
  try {
    if (!auth.currentUser) {
      throw new Error('Authentication required to update proposal');
    }

    const proposalRef = doc(db, 'proposals', proposalId);
    await setDoc(proposalRef, {
      ...proposalData,
      updatedAt: new Date(),
      lastUpdatedBy: auth.currentUser.uid
    }, { merge: true });
  } catch (error) {
    console.error('Error updating proposal:', error);
    throw error;
  }
};
