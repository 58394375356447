import { useState, useCallback } from 'react';

interface ToastState {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
}

const initialState: ToastState = {
  open: false,
  message: '',
  severity: 'info'
};

export const useToast = () => {
  const [toast, setToast] = useState<ToastState>(initialState);

  const showToast = useCallback((message: string, severity: ToastState['severity'] = 'info') => {
    setToast({
      open: true,
      message,
      severity
    });
  }, []);

  const hideToast = useCallback(() => {
    setToast(prev => ({
      ...prev,
      open: false
    }));
  }, []);

  return {
    toast,
    showToast,
    hideToast
  };
};

export default useToast;
