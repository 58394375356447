import { useState, useEffect, useCallback } from 'react';
import type { PersonalInfo, CompanyInfo } from './types';
import { fetchSettings, saveSettings } from '../../services/firestore';
import { uploadImage, deleteImage } from '../../services/storage';
import type { Settings, PriceSettings, CompanySettings, PriceLevelKey } from '../../services/types';

const defaultPreferences = {
  autoSaveReports: false,
  includeCompanyLogo: false,
  includeCoverBackground: false,
  darkMode: false,
  compactView: false
};

const regions = [
  'WA with <100 doors',
  'WA with >100 doors',
  'OR with <100 doors',
  'OR with >100 doors'
] as const;

type PropertyType = 'SFH' | 'TH' | 'Condo';
const propertyTypes: PropertyType[] = ['SFH', 'TH', 'Condo'];

const priceLevels: PriceLevelKey[] = [
  'Level 1',
  'Level 2',
  'Level 3',
  'Level 4',
  '3 year (1+3+3)',
  '3 year (2+3+3)'
];

const defaultPriceLevels = {
  'Level 1': '0',
  'Level 2': '0',
  'Level 3': '0',
  'Level 4': '0',
  '3 year (1+3+3)': '0',
  '3 year (2+3+3)': '0'
};

const defaultPrices: PriceSettings = {
  'WA with <100 doors': {
    'SFH': {
      'Level 1': '2400.00',
      'Level 2': '2100.00',
      'Level 3': '1200.00',
      'Level 4': '2400.00',
      '3 year (1+3+3)': '4320.00',
      '3 year (2+3+3)': '4050.00'
    },
    'TH': {
      'Level 1': '2600.00',
      'Level 2': '2100.00',
      'Level 3': '1200.00',
      'Level 4': '2400.00',
      '3 year (1+3+3)': '4500.00',
      '3 year (2+3+3)': '4050.00'
    },
    'Condo': {
      'Level 1': '2600.00',
      'Level 2': '2100.00',
      'Level 3': '1200.00',
      'Level 4': '2400.00',
      '3 year (1+3+3)': '4500.00',
      '3 year (2+3+3)': '4050.00'
    }
  }
};

export const useSettingsForm = () => {
  const [basicLogo, setBasicLogo] = useState<string | null>(null);
  const [coverBackground, setCoverBackground] = useState<string | null>(null);
  const [proposalBase, setProposalBase] = useState<string[]>(['', '', '', '']);
  const [invoiceBase, setInvoiceBase] = useState<string | null>(null);
  const [personalInfo, setPersonalInfo] = useState<PersonalInfo>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    title: ''
  });
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>({
    companyName: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    website: '',
    email: '',
    phone: ''
  });
  const [prices, setPrices] = useState<PriceSettings>(defaultPrices);

  const loadSettings = useCallback(async () => {
    try {
      const settings = await fetchSettings();
      if (settings) {
        setBasicLogo(settings.company.basicLogo || null);
        setCoverBackground(settings.company.coverBackground || null);
        setProposalBase(settings.company.proposalBase ? 
          Array.isArray(settings.company.proposalBase) ? 
            settings.company.proposalBase : 
            [settings.company.proposalBase, '', '', ''] :
          ['', '', '', '']
        );
        setInvoiceBase(settings.company.invoiceBase || null);
        setPersonalInfo({
          firstName: settings.personal.firstName || '',
          lastName: settings.personal.lastName || '',
          email: settings.personal.email || '',
          phone: settings.personal.phone || '',
          title: settings.personal.title || ''
        });
        setCompanyInfo({
          companyName: settings.company.companyName || '',
          address: settings.company.address || '',
          city: settings.company.city || '',
          state: settings.company.state || '',
          zipCode: settings.company.zipCode || '',
          website: settings.company.website || '',
          email: settings.company.email || '',
          phone: settings.company.phone || ''
        });
        if (settings.prices) {
          setPrices(settings.prices);
        }
      }
    } catch (error) {
      console.error('Error loading settings:', error);
    }
  }, []);

  useEffect(() => {
    loadSettings();
  }, [loadSettings]);

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>, 
    type: 'basicLogo' | 'coverBackground' | 'invoiceBase'
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const url = await uploadImage(file, `branding/${type}/${file.name}`);
        
        // Update local state
        switch (type) {
          case 'basicLogo':
            setBasicLogo(url);
            break;
          case 'coverBackground':
            setCoverBackground(url);
            break;
          case 'invoiceBase':
            setInvoiceBase(url);
            break;
        }
        
        // Update settings in Firestore
        await saveSettings({
          company: {
            ...companyInfo,
            [type]: url
          }
        });
      } catch (error) {
        console.error(`Error saving ${type}:`, error);
      }
    }
  };

  const handleProposalBaseUpload = (pageIndex: number) => async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      try {
        const url = await uploadImage(file, `branding/proposalBase/page${pageIndex + 1}/${file.name}`);
        
        const newProposalBase = [...proposalBase];
        newProposalBase[pageIndex] = url;
        setProposalBase(newProposalBase);
        
        // Update settings in Firestore
        await saveSettings({
          company: {
            ...companyInfo,
            proposalBase: newProposalBase
          }
        });
      } catch (error) {
        console.error('Error saving proposal base:', error);
      }
    }
  };

  const handleProposalBaseDelete = async (pageIndex: number) => {
    try {
      if (proposalBase[pageIndex]) {
        await deleteImage(proposalBase[pageIndex]);
        const newProposalBase = [...proposalBase];
        newProposalBase[pageIndex] = '';
        setProposalBase(newProposalBase);
        
        // Update settings in Firestore
        await saveSettings({
          company: {
            ...companyInfo,
            proposalBase: newProposalBase
          }
        });
      }
    } catch (error) {
      console.error('Error deleting proposal base:', error);
    }
  };

  const handleInvoiceBaseDelete = async () => {
    try {
      if (invoiceBase) {
        await deleteImage(invoiceBase);
        setInvoiceBase(null);
        
        // Update settings in Firestore
        await saveSettings({
          company: {
            ...companyInfo,
            invoiceBase: null
          }
        });
      }
    } catch (error) {
      console.error('Error deleting invoice base:', error);
    }
  };

  const handleBasicLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileUpload(event, 'basicLogo');
  };

  const handleCoverBackgroundUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileUpload(event, 'coverBackground');
  };

  const handleInvoiceBaseUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileUpload(event, 'invoiceBase');
  };

  const handlePersonalInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPersonalInfo(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCompanyInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCompanyInfo(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (!name) return;

    const [region, propertyType, level] = name.split('|');
    if (!region || !propertyType || !level) return;

    setPrices(prev => {
      const newPrices = { ...prev };
      if (!newPrices[region]) {
        newPrices[region] = {};
      }
      if (!newPrices[region][propertyType]) {
        newPrices[region][propertyType] = { ...defaultPriceLevels };
      }
      newPrices[region][propertyType][level as PriceLevelKey] = value || '0';
      return newPrices;
    });
  };

  const ensureAllPricesExist = (currentPrices: PriceSettings): PriceSettings => {
    const completePrices: PriceSettings = { ...currentPrices };

    regions.forEach(region => {
      if (!completePrices[region]) {
        completePrices[region] = {};
      }

      propertyTypes.forEach(propertyType => {
        if (!completePrices[region][propertyType]) {
          completePrices[region][propertyType] = { ...defaultPriceLevels };
        }

        priceLevels.forEach(level => {
          if (!completePrices[region][propertyType][level]) {
            completePrices[region][propertyType][level] = '0';
          }
        });
      });
    });

    return completePrices;
  };

  const saveAllSettings = async () => {
    try {
      const companySettings: CompanySettings = {
        ...companyInfo
      };

      // Only add non-null branding assets
      if (basicLogo) companySettings.basicLogo = basicLogo;
      if (coverBackground) companySettings.coverBackground = coverBackground;
      if (proposalBase.some(base => base !== '')) companySettings.proposalBase = proposalBase;
      if (invoiceBase) companySettings.invoiceBase = invoiceBase;

      const settings: Settings = {
        company: companySettings,
        personal: personalInfo,
        preferences: defaultPreferences,
        prices: ensureAllPricesExist(prices)
      };

      await saveSettings(settings);
      return true;
    } catch (error) {
      console.error('Error saving settings:', error);
      return false;
    }
  };

  return {
    basicLogo,
    coverBackground,
    proposalBase,
    invoiceBase,
    personalInfo,
    companyInfo,
    prices,
    handleBasicLogoUpload,
    handleCoverBackgroundUpload,
    handleProposalBaseUpload,
    handleProposalBaseDelete,
    handleInvoiceBaseUpload,
    handleInvoiceBaseDelete,
    handlePersonalInfoChange,
    handleCompanyInfoChange,
    handlePriceChange,
    saveAllSettings,
    loadSettings
  };
};
