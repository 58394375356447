import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Template } from '../../../types';
import { styles } from '../../styles/pdfStyles';

interface CausesOfDistressSectionProps {
  template: Template;
}

export const CausesOfDistressSection: React.FC<CausesOfDistressSectionProps> = ({ template }) => {
  const causes = [
    {
      title: 'Installation Error',
      content: template.causesOfDistress.installationError,
      category: 'critical'
    },
    {
      title: 'Inappropriate Materials',
      content: template.causesOfDistress.inappropriateMaterials,
      category: 'moderate'
    },
    {
      title: 'Component Damage',
      content: template.causesOfDistress.componentDamage,
      category: 'critical'
    },
    {
      title: 'Inadequate Design',
      content: template.causesOfDistress.inadequateDesign,
      category: 'moderate'
    },
    {
      title: 'Exposure',
      content: template.causesOfDistress.exposure,
      category: 'informational'
    },
    {
      title: 'Building Movement',
      content: template.causesOfDistress.buildingMovement,
      category: 'critical'
    },
    {
      title: 'Organisms',
      content: template.causesOfDistress.organisms,
      category: 'moderate'
    },
    {
      title: 'Material Properties',
      content: template.causesOfDistress.materialProperties,
      category: 'informational'
    }
  ];

  const getCategoryStyle = (category: string) => {
    switch (category) {
      case 'critical':
        return {
          backgroundColor: '#FFEBEE',
          borderColor: '#D32F2F',
          titleColor: '#D32F2F'
        };
      case 'moderate':
        return {
          backgroundColor: '#FFF3E0',
          borderColor: '#F57C00',
          titleColor: '#F57C00'
        };
      case 'informational':
      default:
        return {
          backgroundColor: '#E1F5FE',
          borderColor: '#0288D1',
          titleColor: '#0288D1'
        };
    }
  };

  return (
    <View style={styles.section}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>Causes of Distress</Text>
      </View>

      {/* Content Container */}
      <View style={[styles.contentBlock, { 
        marginTop: 10,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }]}>
        {causes.map((cause, index) => {
          const categoryStyle = getCategoryStyle(cause.category);
          
          return (
            <View 
              key={cause.title}
              style={[
                {
                  width: '48%',
                  marginBottom: index < causes.length - 2 ? 12 : 0,
                  backgroundColor: categoryStyle.backgroundColor,
                  padding: 10,
                  borderRadius: 4,
                  borderLeft: `4pt solid ${categoryStyle.borderColor}`,
                }
              ]}
            >
              {/* Cause Title */}
              <Text style={{
                fontSize: 14,
                fontFamily: 'Times-Bold',
                color: categoryStyle.titleColor,
                marginBottom: 6,
              }}>
                {cause.title}
              </Text>

              {/* Cause Description */}
              <Text style={{
                fontSize: 12,
                color: '#424242',
                lineHeight: 1.4,
              }}>
                {cause.content || 'Not specified'}
              </Text>
            </View>
          );
        })}
      </View>

      {/* Optional Note */}
      {!causes.some(cause => cause.content) && (
        <Text style={[styles.text, { 
          fontStyle: 'italic',
          textAlign: 'center',
          marginTop: 8,
          color: '#616161'
        }]}>
          No causes of distress have been specified.
        </Text>
      )}
    </View>
  );
};
