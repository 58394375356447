import { Paper, Grid, Box, useTheme, useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FormHeader } from './inspection-form/FormHeader';
import { StepContent } from './inspection-form/StepContent';
import FormStepper from './inspection-form/FormStepper';
import FormNavigation from './inspection-form/FormNavigation';
import { useInspectionForm } from './inspection-form/useInspectionForm';
import { usePhotoUpload } from './inspection-form/photos/usePhotoUpload';
import { useFormSteps } from './inspection-form/hooks/useFormSteps';
import { PhotoDetails } from './inspection-form/types';

const steps = ['Property Details', 'Photos & Findings', 'General Notes'];

const InspectionForm = () => {
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const {
    formData,
    setFormData,
    currentReportId,
    saving,
    saveProgress,
    handleChange,
    handleSubmit
  } = useInspectionForm(id);

  const {
    uploading,
    handlePhotoUpload,
    handlePhotoUpdate
  } = usePhotoUpload(formData, setFormData, currentReportId);

  const {
    activeStep,
    handleNext,
    handleBack,
    isStepValid,
    onStepValidationChange
  } = useFormSteps({ formData, saveProgress });

  const handlePhotoUpdateWrapper = (photo: PhotoDetails) => {
    handlePhotoUpdate(photo);
  };

  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: isMobile ? 2 : 4,
        mx: isMobile ? -2 : 0,
        borderRadius: isMobile ? 0 : 1
      }}
    >
      <FormHeader isEditing={Boolean(currentReportId)} />

      <Box sx={{ 
        overflowX: 'auto',
        mx: isMobile ? -2 : 0,
        px: isMobile ? 2 : 0
      }}>
        <FormStepper activeStep={activeStep} steps={steps} />
      </Box>

      <Box 
        component="form" 
        onSubmit={handleSubmit} 
        sx={{ 
          mt: 3,
          '& .MuiTextField-root': { 
            mb: 2,
            width: '100%'
          },
          '& .MuiButton-root': { 
            minHeight: isMobile ? '48px' : '36px',
            minWidth: isMobile ? '48px' : '64px',
            my: 1
          },
          '& .MuiInputLabel-root': {
            fontSize: isMobile ? '1rem' : 'inherit'
          },
          '& .MuiOutlinedInput-input': {
            padding: isMobile ? '14px 16px' : '16.5px 14px'
          }
        }}
      >
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={12}>
            <StepContent
              activeStep={activeStep}
              formData={formData}
              uploading={uploading}
              handleChange={handleChange}
              handlePhotoUpload={handlePhotoUpload}
              handlePhotoUpdate={handlePhotoUpdateWrapper}
              setFormData={setFormData}
              onStepValidationChange={onStepValidationChange}
            />
          </Grid>

          <Grid item xs={12}>
            <FormNavigation
              activeStep={activeStep}
              totalSteps={steps.length}
              onBack={handleBack}
              onNext={handleNext}
              onSubmit={handleSubmit}
              saving={saving}
              isStepValid={isStepValid}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default InspectionForm;
