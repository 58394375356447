import React from 'react';
import { Snackbar, Alert, useTheme, useMediaQuery } from '@mui/material';

interface ToastProps {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
  onClose: () => void;
}

const Toast: React.FC<ToastProps> = ({ open, message, severity, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{
        vertical: isMobile ? 'bottom' : 'top',
        horizontal: 'center'
      }}
      sx={{
        bottom: isMobile ? 16 : 24
      }}
    >
      <Alert
        onClose={onClose}
        severity={severity}
        variant="filled"
        sx={{
          width: '100%',
          fontSize: isMobile ? '0.875rem' : '1rem',
          '& .MuiAlert-icon': {
            fontSize: isMobile ? '1.25rem' : '1.5rem'
          }
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
