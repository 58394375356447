import { FC } from 'react';
import FeedbackSnackbar from '../FeedbackSnackbar';

interface FeedbackSnackbarsProps {
  success: boolean;
  error: string | null;
  onSuccessClose: () => void;
  onErrorClose: () => void;
}

export const FeedbackSnackbars: FC<FeedbackSnackbarsProps> = ({
  success,
  error,
  onSuccessClose,
  onErrorClose
}) => (
  <>
    <FeedbackSnackbar
      open={success}
      message="Template saved successfully!"
      severity="success"
      onClose={onSuccessClose}
    />

    <FeedbackSnackbar
      open={!!error}
      message={error || 'An error occurred'}
      severity="error"
      onClose={onErrorClose}
    />
  </>
);
