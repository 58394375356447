import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getAnalytics, Analytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyCrUNubZoVZ7AynohSgDY6_13vLkuc-V8Q",
  authDomain: "bei-app-537a2.firebaseapp.com",
  projectId: "bei-app-537a2",
  storageBucket: "bei-app-537a2.appspot.com",
  messagingSenderId: "494252977613",
  appId: "1:494252977613:web:83a62b4ae83adeb9d02884",
  measurementId: "G-GJ6PJMGRGW"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

// Initialize Analytics only in production and if supported
let analytics: Analytics | null = null;
if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
  analytics = getAnalytics(app);
}
export { analytics };
