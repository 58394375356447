import React from 'react';
import { View, Text } from '@react-pdf/renderer';
import { Template } from '../../../types';
import { styles } from '../../styles/pdfStyles';

interface BuildingComponentsSectionProps {
  template: Template;
}

export const BuildingComponentsSection: React.FC<BuildingComponentsSectionProps> = ({ template }) => {
  const components = [
    { name: 'Roofing', value: template.buildingComponents.roofing },
    { name: 'Siding', value: template.buildingComponents.siding },
    { name: 'Windows and Doors', value: template.buildingComponents.windowsAndDoors },
    { name: 'Decking', value: template.buildingComponents.decking },
    { name: 'Foundation', value: template.buildingComponents.foundation },
  ];

  return (
    <View style={styles.section}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={styles.title}>Building Components</Text>
      </View>

      {/* Table Container */}
      <View style={[styles.contentBlock, { marginTop: 15 }]}>
        {/* Table Header */}
        <View style={styles.tableHeaderRow}>
          <Text style={[styles.tableHeaderCell, { flex: 1 }]}>Component</Text>
          <Text style={[styles.tableHeaderCell, { flex: 2 }]}>Description</Text>
        </View>

        {/* Table Rows */}
        {components.map((component, index) => (
          <View 
            key={component.name}
            style={[
              styles.tableRow,
              { backgroundColor: index % 2 === 0 ? '#FFFFFF' : '#F5F5F5' }
            ]}
          >
            <Text style={[styles.tableCell, { 
              flex: 1,
              color: '#1976D2',
              fontFamily: 'Times-Bold'
            }]}>
              {component.name}
            </Text>
            <Text style={[styles.tableCell, { flex: 2 }]}>
              {component.value || 'Not specified'}
            </Text>
          </View>
        ))}
      </View>

      {/* Optional Note */}
      {!components.some(comp => comp.value) && (
        <Text style={[styles.text, { 
          fontStyle: 'italic',
          textAlign: 'center',
          marginTop: 10,
          color: '#616161'
        }]}>
          No building components have been specified.
        </Text>
      )}
    </View>
  );
};
