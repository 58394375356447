import React from 'react';
import { Box } from '@mui/material';
import UploadArea from './UploadArea';
import PhotoList from './PhotoList';
import Toast from '../../common/Toast';
import type { PhotoDetails, PhotoUploadProps } from '../types';
import useToast from '../../../hooks/useToast';

const PhotoUpload: React.FC<PhotoUploadProps> = ({
  photos,
  uploading,
  onPhotoUpload,
  onPhotoUpdate,
  onValidationChange
}) => {
  const { toast, showToast, hideToast } = useToast();

  const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;

    const file = e.target.files[0];
    const maxSize = 5 * 1024 * 1024; // 5MB

    if (file.size > maxSize) {
      showToast('Photo size must be less than 5MB', 'error');
      return;
    }

    if (!file.type.startsWith('image/')) {
      showToast('Please select a valid image file', 'error');
      return;
    }

    try {
      onPhotoUpload(e);
    } catch (error) {
      showToast('Failed to upload photo. Please try again.', 'error');
    }
  };

  const handlePhotoUpdate = (photo: PhotoDetails) => {
    onPhotoUpdate(photo);
    showToast('Photo details updated', 'success');
  };

  const handleValidationChange = (isValid: boolean) => {
    if (onValidationChange) {
      onValidationChange(isValid);
    }
  };

  return (
    <Box>
      <UploadArea
        uploading={uploading}
        onPhotoUpload={handlePhotoUpload}
      />
      <PhotoList
        photos={photos}
        onPhotoUpdate={handlePhotoUpdate}
        onValidationChange={handleValidationChange}
      />
      <Toast 
        open={toast.open}
        message={toast.message}
        severity={toast.severity}
        onClose={hideToast}
      />
    </Box>
  );
};

export default PhotoUpload;
