import { Font } from '@react-pdf/renderer';
import { InspectionReport } from '../../types';
import { fetchSettings, fetchTemplate } from '../../firestore';
import { auth } from '../../../firebase/config';

// Initialize fonts with proper error handling
const initializeFonts = async () => {
  try {
    await Font.register({
      family: 'Roboto',
      src: '/fonts/Roboto-Regular.ttf'
    });

    await Font.register({
      family: 'Roboto-Bold',
      src: '/fonts/Roboto-Bold.ttf'
    });
  } catch (error) {
    console.error('Error registering fonts:', error);
  }
};

// Initialize fonts immediately
initializeFonts();

export const getConditionStyle = (severity: string) => {
  switch (severity.toLowerCase()) {
    case 'critical':
      return { backgroundColor: '#ffebee', color: '#d32f2f' };
    case 'moderate':
      return { backgroundColor: '#fff3e0', color: '#f57c00' };
    case 'informational':
      return { backgroundColor: '#e8f5e9', color: '#388e3c' };
    default:
      return { backgroundColor: '#f5f5f5', color: '#757575' };
  }
};

const fetchImageAsBase64 = async (url: string): Promise<string> => {
  const placeholderImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

  if (!url) {
    console.log('No URL provided for image fetch');
    return placeholderImage;
  }

  try {
    console.log('Fetching image from URL:', url);
    const imageUrl = new URL(url);
    let response: Response;

    if (imageUrl.hostname.includes('firebasestorage.googleapis.com')) {
      // For Firebase Storage URLs
      const token = await auth.currentUser?.getIdToken();
      if (!token) {
        console.error('No authentication token available');
        return placeholderImage;
      }

      console.log('Fetching Firebase Storage image with auth token');
      response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Firebase ${token}`,
          'Accept': 'image/*'
        },
        mode: 'cors'
      });
    } else {
      // For other URLs
      console.log('Fetching non-Firebase image');
      response = await fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'image/*'
        },
        mode: 'cors'
      });
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    console.log('Successfully fetched image blob:', blob.type, blob.size);
    
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data) {
          console.log('Successfully converted image to base64');
          resolve(base64data.toString());
        } else {
          console.error('Failed to convert image to base64');
          resolve(placeholderImage);
        }
      };
      
      reader.onerror = () => {
        console.error('Error reading blob data');
        resolve(placeholderImage);
      };
      
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error fetching image:', error);
    return placeholderImage;
  }
};

const processPhotos = async (report: InspectionReport) => {
  if (!report.photos) return report;

  try {
    const processedPhotos = await Promise.all(
      report.photos.map(async (photo) => {
        if (!photo.url) return photo;
        const base64Image = await fetchImageAsBase64(photo.url);
        return { ...photo, url: base64Image };
      })
    );

    return { ...report, photos: processedPhotos };
  } catch (error) {
    console.error('Error processing photos:', error);
    return report;
  }
};

const processImages = async (settings: any) => {
  if (!settings) return settings;

  try {
    console.log('Processing company images from settings:', {
      hasBasicLogo: !!settings.company?.basicLogo,
      hasCoverBackground: !!settings.company?.coverBackground
    });

    const processedSettings = { ...settings };
    
    // Process all company logos and images
    if (settings.company) {
      const company = { ...settings.company };
      
      // Process basic logo
      if (company.basicLogo) {
        console.log('Processing basic logo URL:', company.basicLogo);
        company.basicLogo = await fetchImageAsBase64(company.basicLogo);
      }
      
      // Process cover background
      if (company.coverBackground) {
        console.log('Processing cover background URL:', company.coverBackground);
        company.coverBackground = await fetchImageAsBase64(company.coverBackground);
      }

      processedSettings.company = company;
    }

    console.log('Finished processing company images');
    return processedSettings;
  } catch (error) {
    console.error('Error processing company images:', error);
    return settings;
  }
};

export const loadPdfDependencies = async (report: InspectionReport) => {
  try {
    // Ensure fonts are loaded before proceeding
    await initializeFonts();
    
    const [settings, template] = await Promise.all([
      fetchSettings(),
      fetchTemplate()
    ]);

    console.log('Fetched settings:', {
      hasCompany: !!settings?.company,
      companyName: settings?.company?.companyName,
      hasBasicLogo: !!settings?.company?.basicLogo,
      includeCompanyLogo: settings?.preferences?.includeCompanyLogo
    });

    if (!settings || !settings.company?.companyName) {
      throw new Error('Please configure your company name in Settings before generating PDFs.');
    }

    if (!template) {
      throw new Error('Please configure your report template in Settings before generating PDFs.');
    }

    // Process all images in parallel
    const [processedReport, processedSettings] = await Promise.all([
      processPhotos(report),
      processImages(settings)
    ]);

    console.log('Processed settings:', {
      hasCompany: !!processedSettings?.company,
      hasBasicLogo: !!processedSettings?.company?.basicLogo,
      includeCompanyLogo: processedSettings?.preferences?.includeCompanyLogo
    });

    return { 
      settings: processedSettings, 
      template, 
      report: processedReport 
    };
  } catch (error) {
    console.error('Error loading PDF dependencies:', error);
    throw error;
  }
};

export const downloadPdf = async (blob: Blob, reportId: string) => {
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `inspection-report-${reportId}.pdf`;
  link.click();
  URL.revokeObjectURL(url);
};
